<template>
  <h3>RELEASE</h3>
  <section v-if="$route.params.num">
    <InterviewDetailView></InterviewDetailView>
  </section>
  <section v-else>
    <div class="day-wrap">
      <DayBoxView @gameDate="changeDate" :game-date="this.selectedDate"></DayBoxView>
    </div>

    <div class="board">
      <SearchZoneView ref="searchzone" :section-list="this.sectionList" :select-box="this.selectBox" :data-section="this.dataSc" :search="txtSearch" :max-length="20" @search="setSearch"></SearchZoneView>
      <InterviewListView :interview-list="this.interviewList"></InterviewListView>
      <PagingView :page="this.page" @onPage="onPage"></PagingView>
    </div>
  </section>
</template>

<script>

import DayBoxView from '@/components/Common/DayBoxView.vue';
import SearchZoneView from '@/components/Common/SearchZoneView.vue';
import InterviewListView from '@/components/Interview/InterviewListView.vue';
import InterviewDetailView from '@/components/Interview/InterviewDetailView.vue';
import PagingView from '@/components/Layout/PagingView.vue';

import Common from "@/utils/common.js"
import InterviewService from "@/utils/service/interviewService.js"

export default{ 
  name:'InterviewView',
  components:{DayBoxView, SearchZoneView, InterviewListView, InterviewDetailView, PagingView},
  emits: ['session'],
  data() {
    return{
      common: new Common(),
      interviewService : new InterviewService(),

      interviewList: [],  // Release 목록

      // 검색창 관련 data
      sectionList: [],   // 카테고리 목록
      sectionId: '',     // 카테고리 값
      selectBox: [{value: 'TITLE_NM', text: '제목'}],
      dataSc: 'TITLE_NM',
      txtSearch: '',

      selectedDate: '',
      
      page: {
        total: 1,       // 총 데이터 수
        page: 1,        // 현재 페이지
        count: 10       // 한 페이지에 표시 수
      },

      sessionInterval: null,
    };
  },
  setup() {},
  created() {
    this.sessionInterval = setInterval(() => {
      this.$emit('session');
    }, 15000);
  },
  mounted() {
    this.selectedDate = this.common.yyyyMMdd(new Date(),'');
    this.getSectionList();
    this.getInterviewList(1);
  },
  unmounted() {
    clearInterval(this.sessionInterval);
  },
  methods: {
    // 달력 변경
    changeDate(data){
      this.selectedDate = data;
      this.getInterviewList(1);
    },

    // 데이터 초기화
    initData(){
      this.$refs.searchzone.selSection = '';
      this.$refs.searchzone.selCondition = 'TITLE_NM';
      this.$refs.searchzone.txtSearch = '';

      const obj = {section: '', condition: 'TITLE_NM', text: ''}
      this.setSearch(obj);
    },

    // 카테고리 리스트
    async getSectionList() {
      let result = await this.interviewService.$getSectionList();
      //console.log(result);
      this.sectionList = result.data.sectionList || [];
    },

    // 인터뷰 리스트
    async getInterviewList(pageNo) {
      let result = await this.interviewService.$getInterviewList(this.selectedDate, this.sectionId, this.dataSc, this.txtSearch, this.page.count, pageNo);
      //console.log("getInteviewList", result);
      this.interviewList = result.data.interviewList || [];
      this.page.total = result.data.totalCnt;
      this.page.page = pageNo;
    },

    // 페이지 변경 시
    onPage(page) {
      this.getInterviewList(page);
    },

    // 검색 click
    setSearch(data) {
      //console.log("검색 로직", data)
      this.sectionId = data.section;
      this.dataSc = data.condition;
      this.txtSearch = data.text;
      this.getInterviewList(1);
    }
  }
}
</script>
