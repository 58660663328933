<template>
  <div class="title">
    <ul class="tab">
      <li :class="{on: this.$route.params.detail == 'livetext'}"><router-link to="/broadcast/livetext">문자중계</router-link></li>
      <li :class="{on: this.$route.params.detail == 'analyze'}"><router-link to="/broadcast/analyze">투구분석표</router-link></li>
      <li :class="{on: this.$route.params.detail == 'homerun'}"><router-link to="/broadcast/homerun">홈런분석표</router-link></li>
      <li :class="{on: this.$route.params.detail == 'issue'}"><router-link to="/broadcast/issue">경기이슈</router-link></li>
    </ul>
  </div>
</template>
<script>

export default{ 
  name:'DetailTop',
  components:{},
  data() {
    return{
      sampleData: ''
    };
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {}
}
</script>
