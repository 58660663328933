<template>
  <router-view/>
</template>

<script>
// // import "@/assets/css/jquery-ui.min.css"
 import "@/assets/css/reset.css"
 import "@/assets/css/common.css"
 import "@/assets/css/font.css"
 import "@/assets/css/layout.css"
 import "@/assets/css/contents.css"
// // import "@/assets/css/jquery.bxslider.css"
 import "@/assets/css/s2i-analyse.css"
// import 'video.js/dist/video-js.min.css'

export default {
  name: 'App',
  components: {
    
  },
  mounted(){
        // KeyUp Event add
        document.addEventListener("keyup", this.handleKeyup);
  },
  beforeUnmount() {
    // KeyUp Event remove 
    document.removeEventListener("keyup", this.handleKeyup);
  },
  methods: {
    // keyUp Event Handler
    handleKeyup(e) {
      switch (e.which) {
        case 44: // PrintScreen
          alert(`본 서비스에서 제공하는 정보의 저작 권리는 NC 다이노스와 스포츠투아이㈜에게 있으며,\nNC 다이노스 관련 미디어취재를 위해서 활용할 수 있습니다.\n이를 개인적, 상업적 용도로 (정보 열람, 자료 다운로드, 화면 캡처, 제 3자 제공 등) 사용하는 것은 엄격히 제한됩니다`);
          break;
      }
    }
  }
}
</script>


