<template>
  <div class="board">
    <table class="tb type-view">
      <tr>
        <th>
          <span class="badge">{{txtSection}}</span>
          <span class="title">{{txtTitle}}</span>
          <p class="info">
            <span>경기일 : {{gameDate}}</span>
            <span>상대팀 : {{vsTeam}}</span>
            <span>장소 : {{stadium}}</span>
          </p>
        </th>
      </tr>
      <tr class="file-attach" v-for="file in this.fileList" :key="file.index">
        <td>
          <a href="#" @click.prevent="downFile(file.FILE_LK, file.FILE_NM)">
            <i class="icon-file"></i>
            {{file.FILE_NM}}
          </a>
        </td>
      </tr>
      <tr v-show="this.interviewVod.isVod">
        <td>
          <div style="width:1000px;height:600px;">
            <video class="video-js"
                    ref="videoPlayer" 
                    :src="interviewVod.vodLk"
                    style="width:100%;height:100%;"
                    />
          </div>
        </td>
      </tr>
      <tr class="view">
        <td>
          <div class="ck-content" v-html="interviewCt">
          </div>
        </td>
      </tr>
    </table>

    <div class="btn-wrap">
      <router-link to="/interview" class="btn">목록</router-link>
    </div>
  </div>
</template>
<script>

import axios from 'axios';
import videojs from 'video.js';
import 'video.js/dist/video-js.min.css'

import InterviewService from "@/utils/service/interviewService.js"
import MemberService from '@/utils/service/memberService';
import '@ckeditor/ckeditor5-build-classic';

export default{ 
  name:'InterviewDetailView',
  components:{},
  data() {
    return{
      interviewService: new InterviewService(),
      memberService: new MemberService(),

      txtSection: '',      // 카테고리
      txtTitle: '',        // 타이틀
      gameDate: '',        // 경기일
      vsTeam: '',          // 상대팀
      stadium: '',         // 장소 
      fileList: [],        // 파일
      interviewVod:{
        isVod: false,
        vodLk: ''
      },
      interviewCt: '',       // 내용

      player: null,

      session: {}
    };
  },
  setup() {},
  created() {},
  mounted() {
    this.getInterview(this.$route.params.num);

    const options = {
        autoplay: true,
        controls: true,
      };
    if(this.$refs.videoPlayer){
      this.player = videojs(this.$refs.videoPlayer, options);
      //this.player.play();
    }
  },
  unmounted() {},
  methods: {
    getSession(){
			this.memberService.$session().then(response => {
				//console.log('getSession', response.data)
        if(response.data.length > 0){
          this.session = JSON.parse(response.data);
        }
      })
    },
    
    // 해당 인터뷰 정보 불러오기
    getInterview(interviewSe){
      this.getSession();


      //console.log("조회 로직", interviewSe);

      this.interviewService.$getInterview(interviewSe).then(result => {
        if(result.data.code == 100){
          //console.log(result);

          if(result.data.SECTION_ID == '1' && this.session.U_SC == '0') {
            this.$router.replace('/interview');
          } else {
            this.txtSection = result.data.SECTION_NM;             // 구분
            this.txtTitle = result.data.TITLE_NM;                 // 제목
            this.gameDate = result.data.G_DT;                     // 경기일
            this.vsTeam = result.data.VS_T_NM;                    // 상대팀
            this.stadium = result.data.S_NM;                      // 장소

            this.fileList = result.data.fileList;                 // 첨부파일

            if(result.data.VOD_LK.length > 0){                    // 영상
              //this.interviewVod = {isVod: true, vodLk: `http://test-rstp.sports2i.com/NCPB/${result.data.VOD_LK}`}
              this.interviewVod = {isVod: true, vodLk: `http://rstp.sports2i.com/NCPB/${result.data.VOD_LK}`}
            } else {
              this.interviewVod = {isVod: false}
            }
            this.interviewCt = result.data.RELEASE_CT;            // 내용
          }
        }
      })
    },

    // 첨부파일 다운로드
    downFile(fileLk, fileNm){

      const fileUrl = `/File/download`; // API 엔드포인트 URL

      axios.get(fileUrl, {
        params:{
          file: fileLk
        },
        responseType: 'blob', // 파일 다운로드를 위해 responseType을 'blob'으로 설정
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: [response.data.type] }));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', fileNm); // 다운로드할 파일명 설정
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error('Failed to download file:', error);
      });
    }
  }
}
</script>
