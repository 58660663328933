<template>
  <div class="board table">
    <SituationHitterView ref="situationHitter" :game-info="gameInfo"></SituationHitterView>
    <SituationPitcherView ref="situationPitcher" :game-info="gameInfo"></SituationPitcherView>
  </div>
</template>
<script>

import SituationHitterView from '@/components/Broadcast/Situation/SituationHitterView.vue';
import SituationPitcherView from '@/components/Broadcast/Situation/SituationPitcherView.vue';

export default{ 
  name:'SituationPlayerView',
  components:{SituationHitterView, SituationPitcherView},
  props: {
    gameInfo: {
      type: Object
    }
  },
  data() {
    return{
    };
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    getAllSituationPlayerData() {
      this.$refs.situationHitter.getGameHitter();
      this.$refs.situationPitcher.getGamePitcher();
    }
  }
}
</script>
