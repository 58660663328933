<template>
  <div class="board data">
    <div class="box">
      <img class="pic" :src="pitcherInfo.IMG_LK" :alt="pitcherInfo.P_NM">
      <dl>
        <dt>
          <span>NO.{{pitcherInfo.BACK_NO}}</span> {{pitcherInfo.P_NM}}
        </dt>
        <dd>
          · 투구수 {{pitcherInfo.TOTAL_PIT_CN}}개    
        </dd>
        <dd>
          · K : {{pitcherInfo.KK_CN}}
        </dd>
      </dl>
      <div class="tb-box">
        <table class="tb-mini fixed">
          <colgroup>
            <col width="">
            <col width="32px">
            <col width="32px">
            <col width="32px">
            <col width="32px">
            <col width="32px">
            <col width="32px">
          </colgroup>
          <tr>
            <th>구종</th>
            <th>최저</th>
            <th>최고</th>
            <th>평균</th>
            <th>S</th>
            <th>B</th>
            <th>총투구</th>
          </tr>
          <tr v-for="ballKind in ballKindList" :key="ballKind">
            <td>{{ballKind.PIT_KIND_NM}}</td>
            <td>{{ballKind.MIN_SPEED_VA}}</td>
            <td>{{ballKind.MAX_SPEED_VA}}</td>
            <td>{{ballKind.AVG_SPEED_VA}}</td>
            <td>{{ballKind.STRIKE_CN}}</td>
            <td>{{ballKind.BALL_CN}}</td>
            <td>{{ballKind.TOTAL_PIT_CN}}</td>
          </tr>
          
        </table>
      </div>
    </div>
  </div>
</template>
<script>

import ReportService from "@/utils/service/reportService.js"

export default{ 
  name:'PitcherView',
  components:{},
  emits:['nowPitcher'],
  data() {
    return{
      reportService : new ReportService(),

      ballKindList: [],
      arrBallKind: ["직/투/싱", "슬/컷", "체인", "포크", "커/너"],
      pitcherInfo:{},
    };
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    // getPitcherInfo(gameId, nowInfo){
    //   if(gameId.length > 0){   // 경기 있는 경우
    //     this.reportService.$getPitcherInfo(gameId, nowInfo.inn, nowInfo.tb).then(result => {
    //       console.log("getPitcherInfo", result)
    //       if(result.data.code == '100'){
    //         this.ballKindList = result.data.ballKindList || [];
    //         this.pitcherInfo = result.data.pitcherInfo || {};
    //         this.pitcherInfo.IMG_LK = result.data.pitcherInfo.P_ID
    //                                 ? `https://secnlassrepp3167611.cdn.ntruss.com/person/middle/${result.data.pitcherInfo.SEASON_ID}/${result.data.pitcherInfo.P_ID}.jpg`
    //                                 : `https://secnlassrepp3167611.cdn.ntruss.com/person/middle/noImage.gif`;
            
    //         this.$emit('nowPitcher', this.pitcherInfo)
    //       }
    //     })
    //   } else{                 // 경기 없는 경우
    //     this.ballKindList = [];                
    //     this.arrBallKind.forEach(element => {
    //       this.ballKindList.push({"PIT_KIND_NM": element});
    //     });
    //     this.pitcherInfo = {};
    //     this.pitcherInfo.IMG_LK = `https://secnlassrepp3167611.cdn.ntruss.com/person/middle/noImage.gif`

    //     this.$emit('nowPitcher', this.pitcherInfo)
    //   }
    // },

    getPitcherInfo(gameId, nowInfo){
      if(gameId.length > 0){   // 경기 있는 경우
        this.reportService.$getPitcherInfo(gameId, nowInfo.inn, nowInfo.tb).then(result => {
          //console.log("getPitcherInfo", result)
          if(result.data.code == '100'){
            this.ballKindList = result.data.ballKindList || [];
            this.pitcherInfo = result.data.pitcherInfo || {};
            this.pitcherInfo.IMG_LK = result.data.pitcherInfo.P_ID
                                    ? `https://lgcxydabfbch3774324.cdn.ntruss.com/KBO_IMAGE/person/middle/${result.data.pitcherInfo.SEASON_ID}/${result.data.pitcherInfo.P_ID}.jpg`
                                    : `https://lgcxydabfbch3774324.cdn.ntruss.com/KBO_IMAGE/person/middle/noimg.png`;
            
            fetch(this.pitcherInfo.IMG_LK)
              .then(response => response.blob())
              .then(blob => {
                const reader = new FileReader();
                reader.onload = () => {
                  const base64Image = reader.result.split(',')[1];
                  //console.log(base64Image);
                  this.pitcherInfo.IMG_LK = `data:image/png;base64,${base64Image}` ;
                };
                reader.readAsDataURL(blob);
              })
              .catch(error => {
                console.error('Error fetching or encoding image:', error);
              });

            this.$emit('nowPitcher', this.pitcherInfo)
          }
        })
      } else{                 // 경기 없는 경우
        this.ballKindList = [];                
        this.arrBallKind.forEach(element => {
          this.ballKindList.push({"PIT_KIND_NM": element});
        });
        this.pitcherInfo = {};
        this.pitcherInfo.IMG_LK = `https://lgcxydabfbch3774324.cdn.ntruss.com/KBO_IMAGE/person/middle/noimg.png`;

        this.$emit('nowPitcher', this.pitcherInfo)
      }
    },

    // getPitcherInfo(gameId, nowInfo){
    //   if(gameId.length > 0){   // 경기 있는 경우
    //     this.reportService.$getPitcherInfo(gameId, nowInfo.inn, nowInfo.tb).then(result => {
    //       console.log("getPitcherInfo", result)
    //       if(result.data.code == '100'){
    //         this.ballKindList = result.data.ballKindList || [];
    //         this.pitcherInfo = result.data.pitcherInfo || {};
    //         this.pitcherInfo.IMG_LK = result.data.pitcherInfo.P_ID
    //                                 ? `https://secnlassrepp3167611.cdn.ntruss.com/person/middle/${result.data.pitcherInfo.SEASON_ID}/${result.data.pitcherInfo.P_ID}.jpg`
    //                                 : `https://secnlassrepp3167611.cdn.ntruss.com/person/middle/noImage.gif`;


    //         const canvas = document.createElement('canvas');
    //         const ctx = canvas.getContext('2d');
            
    //         // 이미지를 로드하고 Base64로 인코딩하기 위해 Image 객체를 사용
    //         var img = new Image();
    //         img.src = this.pitcherInfo.IMG_LK;
    //         img.crossOrigin = 'Anonymous'; // CORS 정책을 위해 crossOrigin 설정

    //         img.onload = () => {
    //           canvas.width = img.width;
    //           canvas.height = img.height;
    //           ctx.drawImage(img, 0, 0);
    //           console.log("onload", ctx)
    //           // Base64로 인코딩된 이미지를 얻습니다.
    //           this.cdnImageUrl = canvas.toDataURL('image/jpeg');
    //         };
    //         this.$emit('nowPitcher', this.pitcherInfo)
    //       }
    //     })
    //   } else{                 // 경기 없는 경우
    //     this.ballKindList = [];                
    //     this.arrBallKind.forEach(element => {
    //       this.ballKindList.push({"PIT_KIND_NM": element});
    //     });
    //     this.pitcherInfo = {};
    //     this.pitcherInfo.IMG_LK = `https://secnlassrepp3167611.cdn.ntruss.com/person/middle/noImage.gif`

    //     this.$emit('nowPitcher', this.pitcherInfo)
    //   }
    // },
  }
}
</script>
