<template>
  <div class="search-zone">
    <div class="sub-select" v-show="this.$route.name == 'interview'"> <!-- /notice 접근 시 sub-select remove 해줘야함 -->
      카테고리
      <select v-model="selSection">
        <option value=''>전체</option>
        <option v-for="item in sectionList" :key="item.SECTION_ID" :value="item.SECTION_ID">{{item.SECTION_NM}}</option>
      </select>
    </div>
    
    <select v-model="selCondition">
      <option v-for="item in selectBox" :key="item.value" :value="item.value">{{item.text}}</option>
    </select>
    <input type="text" :maxlength="this.maxLength" v-model="txtSearch" @keyup.enter="setSearch(this.$route.name)" />
    <a href="#" class="btn search" @click.prevent="setSearch(this.$route.name)">검색</a>
  </div>
</template>
<script>

export default{ 
  name:'SearchZoneView',
  components:{},
  props:{
    sectionList: {
      type: Array
    },
    selectBox: {
      type: Array
    },
    dataSection: {
      type: String
    },
    search:{
      type: String
    },
    maxLength: {
      type: Array
    },
  },
  data() {
    return{
      selSection: ''
      , selCondition: this.dataSection
      , txtSearch: this.search.trim().length > 0 ? this.search : ''       // 검색어
      , txtSearchLen: 0     // 검색창 최대 길이
    };
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    setSearch(section){
      // Release
      if(section == 'interview'){

        this.$emit('search', {section: this.selSection
                              , condition: this.selCondition
                              , text: this.txtSearch})
      } else if (section == 'notice'){  // Notice
        this.$emit('search', {condition: this.selCondition
                              , text: this.txtSearch})
      }
    },
  }
}
</script>
