<template>
    <div ref="Content">
        <div class="box">
            <span class="date" v-if="gameInfo.gameId">{{ gameInfo.gameId.substr(0, 4)}}. {{ gameInfo.gameId.substr(4, 2) }}. {{ gameInfo.gameId.substr(6, 2) }}</span>
            <p class="tit-team" v-if="gameInfo.gameId">{{ gameInfo.awayNm }}<span>vs</span>{{ gameInfo.homeNm }}</p>
            <p class="tit-team" v-else></p>
            <ul class="toggle">
                <li :class="{on:dataSection == '1'}"><a href="#" @click.prevent="changeDataSection('1')">2I</a></li>
                <li :class="{on:dataSection == '2'}"><a href="#" @click.prevent="changeDataSection('2')">NC</a></li>
            </ul>
            <ul class="list">
                <li>
                    <select v-model="homerunInfo" @change="getHomerunDetail()">
                        <option v-for="item in homerunList" :key="item.SEQ_NO" :value="item">{{ getHomerunText(item) }}</option>
                    </select>
                </li>
            </ul>
            <a class="btn-print" href="#" @click="printContent">
                <img src="@/assets/images/contents/icon_print.png" alt="print" />
            </a>
            <a class="btn-pdf" href="#" @click="downloadPDF">
                <img src="@/assets/images/contents/icon_pdf.png" alt="pdf" />
            </a>
        </div>
        <div class="box">
            <div class="tb-box">
                <table class="tb-mini">
                    <colgroup>
                        <col width="163px">
                        <col width="">
                    </colgroup>
                    <tr>
                        <th>구분</th>
                        <th>내용</th>
                    </tr>
                    <tr>
                        <td class="sub-tit">타자</td>
                        <td>{{isHomerunDetail ? `${homerunDetail.BAT_T_NM} ${homerunDetail.BAT_P_NM} (${homerunDetail.BAT_BACK_NO})` : ""}}</td>
                    </tr>
                    <tr>
                        <td class="sub-tit">기록(시즌/통산)</td>
                        <td>{{isHomerunDetail ? `${homerunDetail.isPost ? "포스트" : ""}시즌 ${homerunDetail.SEASON_HR_CN}호 / 통산 ${homerunDetail.TOTAL_HR_CN}호` : ""}}</td>
                    </tr>
                    <tr>
                        <td class="sub-tit">상대투수</td>
                        <td>{{isHomerunDetail ? `${homerunDetail.PIT_T_NM} ${homerunDetail.PIT_P_NM} (${homerunDetail.PIT_BACK_NO})` : ""}}</td>
                    </tr>
                    <tr>
                        <td class="sub-tit">이닝</td>
                        <td>{{isHomerunDetail ? `${homerunDetail.INN_NO}회 ${homerunDetail.TB_SC == 'T' ? '초' : '말'}` : ""}}</td>
                    </tr>
                    <tr>
                        <td class="sub-tit">상황</td>
                        <td>{{isHomerunDetail ? `${homerunDetail.OUT_CN == "0" ? "무" : homerunDetail.OUT_CN}사 ${homerunDetail.BASE_IF}` : ""}}</td>
                    </tr>
                    <tr>
                        <td class="sub-tit">볼카운트</td>
                        <td>{{isHomerunDetail ? `${homerunDetail.BALL_CN}-${homerunDetail.STRIKE_CN} (${homerunDetail.BAT_PIT_CN}구)` : ""}}</td>
                    </tr>
                    <tr>
                        <td class="sub-tit">구종</td>
                        <td>{{isHomerunDetail ? homerunDetail.STUFF_NM : "" }}</td>
                    </tr>
                    <tr>
                        <td class="sub-tit">구속</td>
                        <td>{{isHomerunDetail ? `${homerunDetail.SPEED_VA}km/h` : "" }}</td>
                    </tr>
                    <tr>
                        <td class="sub-tit">비거리</td>
                        <td>{{isHomerunDetail ? `${homerunDetail.DISTANCE_VA}M` : "" }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="box">
            <div class="posi-hr left s2i_size3">
                <span class="s2i_ball" v-if="isHomerunDetail">
                    <a class="ball_type23_44" :style="`top: ${homerunDetail.Y_VA}px; left: ${homerunDetail.X_VA}px;`"></a>
                </span>
            </div>
            <div class="posi-hr right s2i_size3">
                <canvas id="hitLine" width="203" height="203" style="position: absolute; top:15px; left:22px;"></canvas>
                <div class="pts-div">
                    <span class="s2i_ball" v-if="isHomerunDetail">
                        <a class="ball_type23_44" :style="`top: ${homerunDetail.GY_VA}px; left: ${homerunDetail.GX_VA}px;`"></a>
                    </span>
                </div>

                <div class="standard">
                </div>
            </div>
            <img src="@/assets/images/contents/img_banner.png" alt="sports2i banner" />
        </div>
    </div>
</template>
<script>
    import BroadcastService from '@/utils/service/broadcastService';
    import html2canvas from "html2canvas";
    import { jsPDF } from "jspdf";

    export default {
        name: 'DetailHomerun',
        components: {},
        props: {
            gameInfo: {
                type: Object
            }
        },
        data() {
            return {
                broadcastService: new BroadcastService(),
                homerunList: [],
                homerunInfo: {},
                homerunDetail: {},
                isShow: false,
                homerunIndex: 0,

                dataSection: '1',
            };
        },
        setup() { },
        created() { },
        mounted() {
            this.getHomerunList();
        },
        unmounted() { },
        computed: {
            isHomerunDetail() {
                return Object.keys(this.homerunDetail).length;
            }
        },
        watch: {
            "gameInfo.gameId"() {
                this.getHomerunList();
            },
            homerunDetail() {
                this.drawHitLine();
            }
        },
        methods: {
            getHomerunList() {
                if (this.gameInfo.gameId) {  //경기 있는 날
                    this.broadcastService.$getHomerunList(this.gameInfo.gameId, this.dataSection).then(result => {
                        if (result.data.code == "100") {
                            this.homerunList = result.data.homerunList;
                            this.homerunInfo = this.homerunList[0];
                            this.getHomerunDetail();
                        } else if (result.data.code == "204") { //홈런 없음
                            this.homerunList = [];
                            this.homerunDetail = {};
                        }
                    });
                }
                else {
                    this.homerunList = [];
                    this.homerunDetail = {};
                }
            },
            getHomerunDetail() {
                if (this.homerunInfo) {  //경기 있는 날
                    this.homerunIndex = this.homerunList.indexOf(this.homerunInfo);
                    this.broadcastService.$getHomerunDetail(this.homerunInfo, this.dataSection).then(result => {
                        if (result.data.code == "100") {
                            this.homerunDetail = result.data.homerunDetail;

                            if (this.homerunDetail.SR_ID == "4" || this.homerunDetail.SR_ID == "3" || this.homerunDetail.SR_ID == "5" || this.homerunDetail.SR_ID == "7") {
                                this.homerunDetail.isPost = true;
                            } else {
                                this.homerunDetail.isPost = false;
                            }
                            //this.drawHitLine();
                        } else {
                            this.homerunDetail = {};
                        }
                    });
                }
                else {
                    this.homerunDetail = {};
                }
            },
            getHomerunText(homerun) {
                return `${homerun.INN_NO}회${homerun.TB_SC == 'T' ? '초' : '말'}
        ${homerun.BAT_T_NM} ${homerun.BAT_P_NM}(${homerun.BAT_BACK_NO})
        ${this.homerunDetail.isPost ? "포스트" : ""}시즌 ${Number(homerun.BEFORE_SEASON_HR_CN) + Number(homerun.AFTER_SEASON_HR_CN)}호`
            },
            drawHitLine() {
                const canvas = document.getElementById('hitLine');
                const ctx = canvas.getContext('2d');
                ctx.clearRect(0, 0, 203, 203);

                ctx.strokeStyle = 'red';
                ctx.lineWidth = 2;

                ctx.beginPath();
                ctx.moveTo(0, 203);  // 홈플레이트

                ctx.lineTo(this.homerunDetail.GX_VA, parseInt(this.homerunDetail.GY_VA) + 55);

                ctx.stroke();

            },
            // 홈런 분석표 > 인쇄
            printContent() {
                let param = {
                    gameId: this.gameInfo.gameId,
                    awayTeamName: this.gameInfo.awayNm,
                    homeTeamName: this.gameInfo.homeNm,
                    //homerunInfo: this.homerunInfo,
                    homerunIndex: this.homerunIndex,
                    dataSection: this.dataSection
                }

                const queryString = new URLSearchParams(param).toString();
                //window.open(`/print/homerun?${queryString}`, '', 'width=900,height=800');

                const printWindow = window.open(`/print/homerun?${queryString}`, '', 'width=900,height=800');

                setTimeout(() => {
                    printWindow.document.close();
                    printWindow.print();
                    printWindow.onafterprint = function () {
                        printWindow.close();
                    };
                }, 2000);
            },
            // 홈런 분석표 > PDF 파일 다운로드
            downloadPDF() {
                let param = {
                    gameId: this.gameInfo.gameId,
                    awayTeamName: this.gameInfo.awayNm,
                    homeTeamName: this.gameInfo.homeNm,
                    //homerunInfo: this.homerunInfo,
                    homerunIndex: this.homerunIndex,
                    dataSection: this.dataSection
                }
                const queryString = new URLSearchParams(param).toString();


                const pdfWindow = window.open(`/print/homerun?${queryString}`, '', 'width=900,height=800');

                // pdfWindow.onload = async () => {
                //   await new Promise(resolve => {
                //     const checkLoaded = () => {
                //       if (pdfWindow.document.readyState === 'complete') {
                //         resolve();
                //       } else {
                //         setTimeout(checkLoaded, 100);
                //       }
                //     };
                //     checkLoaded();
                //   });

                //   const canvas = await html2canvas(pdfWindow.document.body);

                //   const imgData = canvas.toDataURL('image/png');
                //   const pdfWidth = 210;
                //   const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

                //   const pdf = new jsPDF('p', 'mm', 'a4');
                //   pdf.addImage(imgData, 'png', 0, 0, pdfWidth, pdfHeight);
                //   pdf.save('pdf파일명.pdf');

                //   pdfWindow.close();
                // }


                setTimeout(() => {
                    //const body = pdfWindow.document.getElementById(document.body);
                    //const body = pdfWindow.document.body;
                    const body = pdfWindow.document.getElementById('wrap');
                    //body.style.width = "850px";
                    setTimeout(() => {
                        html2canvas(body).then(canvas => {
                            const imgData = canvas.toDataURL('image/png');
                            //console.log(body)
                            const pdfWidth = 210;
                            const pdfHeight = canvas.height * pdfWidth / canvas.width;

                            // const pdfWidth = body.offsetWidth;
                            //const pdfHeight = body.offsetHeight;

                            // PDF 페이지 크기 설정
                            //const pdf = new jsPDF('p', 'px', [pdfWidth, pdfHeight]);
                            const pdf = new jsPDF('p', 'mm', 'a4');
                            pdf.addImage(imgData, 'png', 13, 13, pdfWidth, pdfHeight);
                            pdf.save('홈런분석표.pdf');

                            pdfWindow.close();

                            //         const imgData = canvas.toDataURL('image/png');
                            //         //console.log(body)
                            //         //const pdfWidth = body.offsetWidth;
                            //         //const pdfHeight = body.offsetHeight;
                            //         const pdfWidth = 210;
                            //         const pdfHeight = canvas.height * pdfWidth / canvas.width;

                            //         //     const imgWidth = 210;
                            // //     const imgHeight = canvas.height * imgWidth / canvas.width;
                            // //     const position = 0;
                            // //     pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                            // //     pdf.save(fileName + '.pdf');
                            //         // PDF 페이지 크기 설정
                            //         const pdf = new jsPDF('p', 'mm', 'a4');
                            //         //const pdf = new jsPDF('p', 'px', [pdfWidth, pdfHeight]);
                            //         pdf.addImage(imgData, 'png', 0, 0, pdfWidth, pdfHeight);
                            //         pdf.save('홈런분석표.pdf');

                            //pdfWindow.close();
                        });
                    }, 2000);
                }, 1000);
            },

            changeDataSection(section) {
                this.dataSection = section;
                this.getHomerunList();
            }


            // // 중복되는 스타일 및 요소 처리를 위한 함수
            // async prepareContentForOutput() {
            //   // // 인쇄 타이틀
            //   // document.querySelectorAll('.print-tit').forEach(th => {
            //   //     th.style.display = 'block';
            //   // });

            //   // // 원하는 스타일 동적 적용
            //   // document.querySelectorAll('table th').forEach(th => {
            //   //     th.style.textAlign = 'center';
            //   // });

            //   // // btn-print와 btn-pdf 숨기기
            //   // const btnPrintEls = document.querySelectorAll('.btn-print, .btn-pdf');
            //   // btnPrintEls.forEach(el => {
            //   //     el.style.display = 'none';
            //   // });

            //   // //const content = popupHomerun.document.getElementById('container');
            //   // const content = this.$refs.Content;
            //   // return await html2canvas(content);
            //   let param = {
            //     gameId: this.gameInfo.gameId,
            //     awayTeamName: this.gameInfo.awayNm,
            //     homeTeamName: this.gameInfo.homeNm,
            //     //homerunInfo: this.homerunInfo,
            //     homerunIndex: this.homerunIndex
            //   }

            //   const queryString = new URLSearchParams(param).toString();
            //   const popupHomerun = window.open(`/print/homerun?${queryString}`, '', 'width=900,height=800');
            //   const body = popupHomerun.document.getElementById('container');
            //   //const content = popupHomerun.document.getElementById('container');
            //   return await html2canvas(body);

            //   //const content = this.$refs.Content;
            //   //return await html2canvas(content);
            // },
            // // 원래 스타일 및 요소 상태로 복원하는 함수.
            // resetOriginalStyles() {
            //   //this.isShow = false;
            //   // 원래 스타일로 복원
            //   document.querySelectorAll('table th').forEach(th => {
            //       th.style.textAlign = '';
            //   });

            //   const btnPrintEls = document.querySelectorAll('.btn-print, .btn-pdf');
            //   btnPrintEls.forEach(el => {
            //       el.style.display = '';
            //   });

            //   document.querySelectorAll('.print-tit').forEach(th => {
            //       th.style.display = 'none';
            //   });
            // },
            // // 홈런 분석표 > 인쇄
            // async printContent() {
            //   //this.isShow = true;
            //   //await this.prepareContentForOutput();
            //   const canvas = await this.prepareContentForOutput();

            //   // const printWindow = window.open('', '_blank');
            //   // printWindow.document.write('<html><head><title>Print</title></head><body>');
            //   // printWindow.document.write('<img src="' + canvas.toDataURL() + '" style="width:100%;height:auto" />');
            //   // printWindow.document.write('</body></html>');
            //   // printWindow.document.close();
            //   // printWindow.focus();

            //   // // 인쇄 명령 실행
            //   // printWindow.print();

            //   // // 인쇄 대화상자가 닫히면 > 새 창도 닫힌다.
            //   // const checkPrintDialogClosed = setInterval(() => {
            //   //     if (printWindow.closed) {
            //   //         clearInterval(checkPrintDialogClosed);
            //   //         return;
            //   //     }
            //   //     if (!printWindow.matchMedia('print').matches) {
            //   //         clearInterval(checkPrintDialogClosed);
            //   //         printWindow.close();
            //   //     }
            //   // }, 200);

            //   // this.resetOriginalStyles();
            // },
            // // 홈런 분석표 > PDF 파일 다운로드
            // async downloadPDF() {
            //   //this.isShow = true;

            //   var fileName = `${this.gameInfo.gameId.substr(0, 8)}`;
            //     fileName += `_ ${this.homerunDetail.INN_NO}회${this.homerunDetail.TB_SC == 1 ? '초' : '말'}`;
            //     fileName += `_${this.homerunDetail.BAT_T_ID}${this.homerunDetail.BAT_P_NM}(${this.homerunDetail.BAT_BACK_NO})`;
            //     fileName += `_시즌${this.homerunDetail.SEASON_HR_CN }호`;
            //     fileName += `_홈런분석표`;

            //     const canvas = await this.prepareContentForOutput();
            //     const imgData = canvas.toDataURL("image/png");
            //     const pdf = new jsPDF();

            //     // A4 크기의 가로 사이즈 지정
            //     const imgWidth = 210;
            //     const imgHeight = canvas.height * imgWidth / canvas.width;
            //     const position = 0;
            //     pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            //     pdf.save(fileName + '.pdf');

            //     this.resetOriginalStyles();
            // }
        }
    }
</script>