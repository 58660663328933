import { createRouter, createWebHistory } from 'vue-router'

import LoginView from '../views/LoginView.vue'
import MainView from '../views/MainView.vue'

// 탭 권한 관련 추가
import CommonService from "@/utils/service/commonService.js"    
const commonService = new CommonService();

async function checkAuth() {
  const result = await commonService.$userAuth();
  return result;
}

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/broadcast/:detail',
    name: 'broadcast',
    component: MainView
  },
  {
    path: '/report',
    name: 'report',
    component: MainView,
    beforeEnter: (to, from, next) => {
      checkAuth().then(result =>{
        if(result.data.code == '100') {
          const userAuth = result.data.objAuth;
          if(userAuth.REPORT){
            next();
          } else {
            next('/broadcast/livetext');
          }
        } else {
          // 오류 시 동작
          next('/broadcast/livetext');
        }
      })
    }
  },
  {
    path: '/report/print',
    name: 'reportprint',
    component: () => import(/* webpackChunkName: "about" */ '../components/Report/ReportPrint.vue')
  },
  {
    path: '/gamenote',
    name: 'gamenote',
    component: MainView,
    //component: () => import(/* webpackChunkName: "about" */ '../views/GameNoteView.vue')
    beforeEnter: (to, from, next) => {
      checkAuth().then(result =>{
        if(result.data.code == '100') {
          const userAuth = result.data.objAuth;
          if(userAuth.GAMENOTE){
            next();
          } else {
            next('/broadcast/livetext');
          }
        } else {
          next('/broadcast/livetext');
        }
      })
    }
  },
  {
    path: '/interview',
    name: 'interview',
    component: MainView,
    beforeEnter: (to, from, next) => {
      checkAuth().then(result =>{
        if(result.data.code == '100') {
          const userAuth = result.data.objAuth;
          if(userAuth.INTERVIEW){
            next();
          } else {
            next('/broadcast/livetext');
          }
        } else {
          next('/broadcast/livetext');
        }
      })
    }
  },
  {
    path: '/interview/:num',
    name: 'interviewDetail',
    component: MainView,
    beforeEnter: (to, from, next) => {
      checkAuth().then(result =>{
        if(result.data.code == '100') {
          const userAuth = result.data.objAuth;
          if(userAuth.INTERVIEW){
            next();
          } else {
            next('/broadcast/livetext');
          }
        } else {
          next('/broadcast/livetext');
        }
      })
    }
  },
  {
    path: '/notice',
    name: 'notice',
    component: MainView,
    beforeEnter: (to, from, next) => {
      checkAuth().then(result =>{
        if(result.data.code == '100') {
          const userAuth = result.data.objAuth;
          if(userAuth.NOTICE){
            next();
          } else {
            next('/broadcast/livetext');
          }
        } else {
          next('/broadcast/livetext');
        }
      })
    }
  },
  {
    path: '/notice/:num',
    name: 'noticeDetail',
    component: MainView,
    beforeEnter: (to, from, next) => {
      checkAuth().then(result =>{
        if(result.data.code == '100') {
          const userAuth = result.data.objAuth;
          if(userAuth.NOTICE){
            next();
          } else {
            next('/broadcast/livetext');
          }
        } else {
          next('/broadcast/livetext');
        }
      })
    }
  },
  {
    path: '/calculation',
    name: 'calculation',
    component: MainView,
    beforeEnter: (to, from, next) => {
      checkAuth().then(result =>{
        if(result.data.code == '100') {
          const userAuth = result.data.objAuth;
          if(userAuth.CAL){
            next();
          } else {
            next('/broadcast/livetext');
          }
        } else {
          next('/broadcast/livetext');
        }
      })
    }
  },
  {
    path: '/print/analysis',
    name: 'analysisprint',
    component: () => import(/* webpackChunkName: "about" */ '../components/Print/PrintAnalysisView.vue')
  },
  {
    path: '/print/homerun',
    name: 'homerunprint',
    component: () => import(/* webpackChunkName: "about" */ '../components/Print/PrintHomerunView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  mode: 'history' 
})


export default router
