<template>
  <div class="box">
    <p class="tb-tit">
      투수
      <span v-if="pitcherData.seasonData?.PIT_P_NM">{{ pitcherData.seasonData.PIT_P_NO }} {{ pitcherData.seasonData.PIT_P_NM }} {{ pitcherData.seasonData.PIT_DIREC_NM }}</span>
    </p>
    <div class="tb-box">
      <table class="tb-mini">
        <colgroup>
          <col width="59px">
        </colgroup>
        <tr>
          <th>{{ gameInfo.gameId.substr(0, 4) }}</th>
          <th>ERA</th>
          <th>경기</th>
          <th>승</th>
          <th>패</th>
          <th>세</th>
          <th>홀드</th>
          <th>이닝</th>
          <th>안타</th>
          <th>볼넷</th>
          <th>삼진</th>
        </tr>
        <tr>
          <td class="sub-tit">시즌</td>
          <td>{{ pitcherData.seasonData?.ERA_RT }}</td>
          <td>{{ pitcherData.seasonData?.GAME_CN }}</td>
          <td>{{ pitcherData.seasonData?.W_CN }}</td>
          <td>{{ pitcherData.seasonData?.L_CN }}</td>
          <td>{{ pitcherData.seasonData?.SV_CN }}</td>
          <td>{{ pitcherData.seasonData?.HOLD_CN }}</td>
          <td>{{ pitcherData.seasonData?.INN2_CN }}</td>
          <td>{{ pitcherData.seasonData?.HIT_CN }}</td>
          <td>{{ pitcherData.seasonData?.BB_CN }}</td>
          <td>{{ pitcherData.seasonData?.KK_CN }}</td>
        </tr>
        <tr>
          <td class="sub-tit">최근5경기</td>
          <td>{{ pitcherData.lastGameData?.ERA_RT }}</td>
          <td>{{ pitcherData.lastGameData?.GAME_CN }}</td>
          <td>{{ pitcherData.lastGameData?.W_CN }}</td>
          <td>{{ pitcherData.lastGameData?.L_CN }}</td>
          <td>{{ pitcherData.lastGameData?.SV_CN }}</td>
          <td>{{ pitcherData.lastGameData?.HOLD_CN }}</td>
          <td>{{ pitcherData.lastGameData?.INN2_CN }}</td>
          <td>{{ pitcherData.lastGameData?.HIT_CN }}</td>
          <td>{{ pitcherData.lastGameData?.BB_CN }}</td>
          <td>{{ pitcherData.lastGameData?.KK_CN }}</td>
        </tr>
        <tr>
          <td class="sub-tit">VS 상대팀</td>
          <td>{{ pitcherData.versusData?.ERA_RT }}</td>
          <td>{{ pitcherData.versusData?.GAME_CN }}</td>
          <td>{{ pitcherData.versusData?.W_CN }}</td>
          <td>{{ pitcherData.versusData?.L_CN }}</td>
          <td>{{ pitcherData.versusData?.SV_CN }}</td>
          <td>{{ pitcherData.versusData?.HOLD_CN }}</td>
          <td>{{ pitcherData.versusData?.INN2_CN }}</td>
          <td>{{ pitcherData.versusData?.HIT_CN }}</td>
          <td>{{ pitcherData.versusData?.BB_CN }}</td>
          <td>{{ pitcherData.versusData?.KK_CN }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>

import BroadcastService from '@/utils/service/broadcastService';

export default{ 
  name:'SituationPitcherView',
  components:{},
  props: {
    gameInfo: {
      type: Object
    }
  },
  data() {
    return{
      broadcastService: new BroadcastService(),
      pitcherData: {}
    };
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  watch: {
    "gameInfo.gameId"() {
      this.getGamePitcher();
    },
  },
  methods: {
    getGamePitcher() {
      if(this.gameInfo.gameId) {  //경기 있는 날
        this.broadcastService.$getGamePitcher(this.gameInfo.srId, this.gameInfo.gameId).then(result => {
          if(result.data.code == "100") {
            this.pitcherData.seasonData = result.data.seasonData;
            this.pitcherData.lastGameData = result.data.lastGameData;
            this.pitcherData.versusData = result.data.versusData;
          }
        });
      } else {  // 경기 없는 날
        this.pitcherData = { seasonData: {}, lastGameData: {}, versusData: {}};
      }
    }
  }
}
</script>
