import axios from 'axios';

class CommonService {

  // 경기 정보
  async $getGameInfo(gameDt){
    const result = axios.get('/Common/gameinfo', {
      params: {
        gameDt: gameDt
      }
    })
    return await result;
  }

  // 최근 작성글 팝업 관련
  async $getWritingList() {
    const result = axios.get(`/Common/writinglist`);
    return await result;
  }

  
  // 탭 권한 확인
  async $userAuth() {
    const result = axios.get("/Common/userAuth");
    return await result;
  }
}

export default CommonService;