<template>
  <div class="day-wrap">
    <DoubleHeaderView v-show="isDoubleHeader" :double-header="this.gameInfo.headerNo" @doubleHeader="changeHeader"></DoubleHeaderView>
    <DayBoxView @gameDate="changeDate"></DayBoxView>
  </div>

  <section v-show="isGameExist" class="left-zone">
    <ScoreBoardView ref="scoreboard" :game-info="gameInfo" :score-data="scoreboardData"></ScoreBoardView>
    <div class="info">
      <SituationGroundView ref="situationGround" :game-info="gameInfo" @stopInterval="stopInterval"></SituationGroundView>
      <SituationPlayerView ref="situationPlayer" :game-info="gameInfo"></SituationPlayerView>
    </div>
    <KeyPointView ref="keypoint" :game-info="gameInfo" :season-id="seasonId" :game-date="gameDate" :interval-obj="intervalObj" @updateKeyPointRun="updateIsKeyPointRun"></KeyPointView>
  </section>

  <section v-show="isGameExist" class="right-zone">
    <DetailView ref="detail" :game-info="gameInfo"></DetailView>
  </section>
</template>

<script>
import DoubleHeaderView from "@/components/Common/DoubleHeaderView.vue";
import DayBoxView from "@/components/Common/DayBoxView.vue";
import ScoreBoardView from "@/components/Broadcast/ScoreBoardView.vue";
import SituationGroundView from "@/components/Broadcast/Situation/SituationGroundView.vue";
import SituationPlayerView from "@/components/Broadcast/Situation/SituationPlayerView.vue";
import KeyPointView from "@/components/Broadcast/KeyPoint/KeyPointView.vue";
import DetailView from "@/components/Broadcast/Detail/DetailView.vue";

import Common from "@/utils/common.js";
import CommonService from "@/utils/service/commonService.js";
import BroadcastService from "@/utils/service/broadcastService.js";

export default {
  name: "BroadcastView",
  components: {
    DoubleHeaderView,
    DayBoxView,
    ScoreBoardView,
    SituationGroundView,
    SituationPlayerView,
    KeyPointView,
    DetailView,
  },
  data() {
    return {
      common: new Common(),
      commonService: new CommonService(),
      broadcastService: new BroadcastService(),

      isDoubleHeader: false,                                                        // true: 더블헤더 O, false: 더블헤더 X
      isGameExist: false,
      gameInfo: { headerNo: 0, srId: "", gameId: "", awayNm: "", homeNm: "", sectionId: "" },      // 해당 날짜 경기 정보
      

      seasonId: 0,                                                                  // 시즌ID
      gameDate: "",                                                                 // 경기날짜
      scoreboardData: { arrAway: [], arrHome: [], rheb: [] },                       // 스코어보드 데이터(원정, 홈, RHEB)

      intervalObj: {
        isLive: false,
        isKeyPointRun: false,   // KEYPOINT 재생/정지 여부
        intervalId: null,
      }
    };
  },
  watch: {
    $route(to, from) {
      // 페이지 이동이 일어나면 인터벌 삭제
      if (to.path.indexOf('/broadcast/') === -1) {
        console.log(to.path);
        console.log(from.path);
        this.stopInterval();
      }
    },
    "gameInfo.gameId"() { 
      this.getScoreBoard();
    }
  },
  setup() {},
  created() {},
  mounted() {
    this.gameDate = this.common.yyyyMMdd(new Date(), "");
    this.seasonId = this.gameDate.substring(0, 4);
    this.getGameInfo(0); 
  },
  unmounted() {},
  methods: {
    // 달력 변경
    changeDate(data) {
      this.gameDate = data;
      this.getGameInfo(0);
    },

    // 더블헤더 변경
    changeHeader(data) {
      this.gameInfo.headerNo = data;
      this.getGameInfo(data);
    },

    // 경기 정보 가져오기
    getGameInfo(headerNo) {
      this.commonService.$getGameInfo(this.gameDate).then((result) => {
        //console.log(result);
        let data = result.data;

        if (data.code == "100") {
          let item = data.gameList || [];

          // 경기 있는 날
          if (data.GAME_CN > 0) {
            this.isGameExist = true;

            if (data.DOUBLE_CK == 1) {
              this.isDoubleHeader = true;
              this.gameInfo.headerNo = headerNo == 0 ? 1 : headerNo;
            } else {
              this.isDoubleHeader = false;
              this.gameInfo.headerNo = item[0].HEADER_NO;
            }

            this.gameInfo.srId = `${item[0].SR_ID}`;
            this.gameInfo.gameId = `${item[0].G_DT}${item[0].AWAY_ID}${item[0].HOME_ID}${this.gameInfo.headerNo}`;
            this.gameInfo.awayNm = `${item[0].AWAY_NM}`;
            this.gameInfo.homeNm = `${item[0].HOME_NM}`;
            this.gameInfo.sectionId = `${item[0].SECTION_ID}`;

            // 23.08.09, 김연중, 문자중계 - 메인페이지 인터벌 설정부분
            this.checkRunInterval();
          } else {  // 경기 없는 날
            this.isGameExist = false;
            this.stopInterval();

            this.gameInfo = {gameId: '', srId: '', awayNm: '-', homeNm: '-', sectionId: ''};
            //this.gameInfo = {gameId: '20230702NCKT0', awayNm: 'NC', homeNm: 'KT'};
            this.scoreboardData = { arrAway: [], arrHome: [], rheb: [] };
          }
        }
      });
    },

    // 상단 스코어보드
    getScoreBoard() {
      //console.log(this.gameInfo.gameId);
      this.broadcastService.$getScoreBoard(this.gameInfo.gameId).then((result) => {
          if (result.data.code == "100") {
            //console.log(result.data);
            this.scoreboardData.arrAway = result.data.awayList || [];
            this.scoreboardData.arrHome = result.data.homeList || [];
            this.scoreboardData.rheb = result.data.rhebList || [];
          }
        });
    },

    // 인터벌 실행 체크
    checkRunInterval() {
      // 처음에 데이터는 불러와야 함
      //if ()
      //this.getAllBroadcastData();
      
      if (this.gameInfo.gameId != "" && this.gameInfo.sectionId == "2") {
        this.startInterval();
      } else {
        this.getScoreBoard();
        this.stopInterval();
      }
    },
    startInterval() {
      // 기존 인터벌이 존재하는 경우 삭제
      if (this.intervalObj.intervalId !== null) {
        //console.log("기존 인터벌 삭제", this.intervalObj.intervalId);
        clearInterval(this.intervalObj.intervalId);
        this.intervalObj.intervalId = null;
      }

      //console.log("인터벌 설정 시작", this.intervalObj.intervalId);
      this.intervalObj.isLive = true;
      this.intervalObj.isKeyPointRun = true;
      this.intervalObj.intervalId = setInterval(this.getAllBroadcastData, 15000);
    },
    stopInterval() {
      //console.log("인터벌 삭제", this.intervalObj.intervalId);
      this.intervalObj.isLive = false;
      this.intervalObj.isKeyPointRun = false;
      clearInterval(this.intervalObj.intervalId);
      this.intervalObj.intervalId = null;
    },
    getAllBroadcastData() {
      //console.log('문자중계 페이지 모든 데이터 로딩', this.intervalObj.intervalId);
      // 문자중계에 필요한 모든 데이터 세팅
      this.getScoreBoard();                                             // 스코어보드
      this.$refs.situationGround.getGroundSituation();                  // 그라운드 상황
      this.$refs.situationGround.getGameSituation();                    // 경기 상황
      this.$refs.situationPlayer.getAllSituationPlayerData();           // 타자/투수 데이터

      // KEYPOINT
      if (this.intervalObj.isKeyPointRun) {
        this.$refs.keypoint.getAllKeyPointData();
      }

      // 우측 탭 (문자중계/투구분석표/홈런분석표/경기이슈)
      this.$refs.detail.getAllDetailData();
    },

    updateIsKeyPointRun(isKeyPointRun) {
      this.intervalObj.isKeyPointRun = isKeyPointRun;

      if (this.intervalObj.isKeyPointRun) {
        // 중지 -> 재생으로 바뀐 경우, 인터벌 초기화
        this.getAllBroadcastData();
        this.startInterval();
      }
    }
  },
};
</script>
