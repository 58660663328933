<template>
  <ul class="day-box">
    <li class="prev">
      <a href="#" @click.prevent="prevClick()"></a>
    </li>
    <li class="day-tit">
      
      <VueDatePicker v-show="this.isPrint != true"  v-model="selectedDate"
                    :format="format"
                    locale="ko"
                    auto-apply
                    :enable-time-picker="false"
                    style='width:165px;top:-6px;'
                    @update:model-value="updateDate()">
      </VueDatePicker>
      <span v-show="this.isPrint == true" class="date">{{this.common.yyyyMMdd(this.selectedDate, '.', true)}}</span>
    </li>
    <li class="next" @click.prevent="nextClick()">
      <a href="#"></a>
    </li>
  </ul>
</template>

<script>

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import Common from "@/utils/common.js"

export default{ 
  name:'DayBoxView',
  components:{VueDatePicker},
  emits: ['gameDate'],
  props:{
    gameDate: {
      type: String
    },
    isPrint: {
      type: Boolean
    },
  },
  data() {
    return{
      common: new Common()
      , selectedDate: new Date()   // 날짜
      , format: ''
    };
  },
  watch: {
    gameDate() {
      this.selectedDate = new Date(this.common.yyyyMMddDash(this.gameDate));
      this.updateDate();
    }
  },
  setup() {},
  created() {},
  mounted() {
    if(this.gameDate){
      this.selectedDate = new Date(this.common.yyyyMMddDash(this.gameDate));
    }
    this.updateDate();
  },
  unmounted() {},
  methods: {
    // 날짜 변경 시
    updateDate(){
      //console.log(this.common.yyyyMMdd(this.selectedDate, ''))
      let date = this.selectedDate;

      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();

      const week = ['일', '월', '화', '수', '목', '금', '토'];
      var dayWeek = week[date.getDay()];

      // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
      if(month < 10){
          month = '0' + month;
      }

      if(day < 10){
          day = '0' + day;
      }

      this.format = `${year}.${month}.${day} (${dayWeek})`;

      this.$emit('gameDate', this.common.yyyyMMdd(this.selectedDate, ''));
    },

    // 이전 날짜 click
    prevClick(){
      const yesterday = new Date(this.selectedDate);
      yesterday.setDate(this.selectedDate.getDate() - 1)
      this.selectedDate = yesterday;
      //console.log(yesterday)
      this.updateDate();
    },

    // 다음 날짜 click
    nextClick(){
      const tomorrow = new Date(this.selectedDate);
      tomorrow.setDate(this.selectedDate.getDate() + 1)
      this.selectedDate = tomorrow;
      //console.log(tomorrow)
      this.updateDate();
    },
  }
}
</script>