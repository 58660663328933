<template>
  <div class="board" v-html="this.content"></div>
</template>

<style src="@/assets/css/edit.css"></style>

<script>
import GameNoteService from "@/utils/service/gamenoteService.js"

export default{ 
  name: 'GameNote',
  components: {},
  data() {
    return{
      gameNoteService: new GameNoteService(),
      content: '' // 게임노트 내용
    };
  },
  setup() {},
  created() {},
  mounted() {
  },
  unmounted() {},
  methods: {
    getGameNoteDetailData(gameDate, gameId) { // 게임노트 호출
      //console.log('------------', gameDate, gameId)
      this.gameNoteService.$getGameNote(gameDate, gameId).then(result => {
        if(result.status === 200) {
          //console.log('gamenote', result.data.CONTENT_CT)
          this.content = result.data.CONTENT_CT; 
        } 
      })
    }
  }
}

// export default{ 
//   name: 'GameNote',
//   components: {},
//   props: {
//     gameDate: { // 경기날짜
//       type: String
//     },
//     gameId: {
//       type: String
//     }
//   },
//   data() {
//     return{
//       gameNoteService: new GameNoteService(),
//       noteDate: '',
//       noteGameId: '',
//       content: '' // 게임노트 내용
//     };
//   },
//   watch: {
//     gameDate(newDate) {
//       this.noteDate = newDate
//       //this.getData(); // 게임노트 호출
//     }, gameId(newGameId) {
//       //console.log(newGameId)
//       this.noteGameId = newGameId
//       this.getData(); // 게임노트 호출
//     }
//   },
//   setup() {},
//   created() {},
//   mounted() {
//     this.noteDate = this.gameDate;
//     this.noteGameId = this.gameId;
//     //this.getData(); // 게임노트 호출
//   },
//   unmounted() {},
//   methods: {
//     getData() { // 게임노트 호출
//       console.log('------------', this.noteGameId)
//       this.gameNoteService.$getGameNote(this.noteDate).then(result => {
//         if(result.status === 200) {
//          this.content = result.data.CONTENT_CT; 
//         }
//       })
//     }
//   }
// }
</script>
