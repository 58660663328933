<template>
  <div class="board graphic">
    <div class="left">
      <LocationLeftView ref="locationL"></LocationLeftView>
    </div>
    <div class="right">
      <LocationRightView ref="locationR"></LocationRightView>
    </div>
  </div>
</template>
<script>

import LocationLeftView from '@/components/Report/ReportRightView/Location/LocationLeftView.vue';
import LocationRightView from '@/components/Report/ReportRightView/Location/LocationRightView.vue';

import ReportService from "@/utils/service/reportService.js"

export default{ 
  name:'PitLocationView',
  components:{LocationLeftView, LocationRightView},
  data() {
    return{
      reportService : new ReportService(),
    };
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    getLocation(gameId, data){
      this.$refs.locationL.getBatDirecLocation(gameId, data);
      this.$refs.locationR.getConditionLocation(gameId, data);
    },
  }
}
</script>
