<template>
  <div class="box">
    <div class="tb-box scroll-wrap" ref="data">
      <div class="fixed-zone">
        <ul class="tit-wrap">
          <li class="name">투수명</li>
          <li class="type">구종</li>
          <li>합계</li>
          <li>최고</li>
          <li>최저</li>
          <li>S</li>
          <li>B</li>
        </ul>
        <table class="tb-mini">
          <colgroup>
            <col width="">
            <col width="52px">
            <col width="30px">
            <col width="30px">
            <col width="30px">
            <col width="30px">
            <col width="30px">
          </colgroup>
          <tbody>
            <tr :key="index" v-for="(item, index) in analysisData">
              <td rowspan="11" v-if="index==0">{{ teamInfo }}</td>
              <td>{{ item.STUFF_NM }}</td>
              <td>{{ item.TOTAL_PIT_CN }}</td>
              <td>{{ item.MAX_SPEED_VA }}</td>
              <td>{{ item.MIN_SPEED_VA }}</td>
              <td>{{ item.PIT_S_CN }}</td>
              <td>{{ item.PIT_B_CN }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="move-zone">
        <div class="scroller">
          <div class="tb-area">
            <ul class="tit-wrap">
              <li :key="index" v-for="(item, index) in maxInning">{{ index + 1 }}</li>
            </ul>
            <table class="tb-mini">
              <colgroup>
                <col :key="index" v-for="(item, index) in maxInning" width="30px">
              </colgroup>
              <tbody>
                <tr :key="count" v-for="(item, count) in inningData">
                  <td :key="index" v-for="(maxItem, index) in maxInning">{{ item[`INN_${index + 1}_NO`] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import IScroll from 'iscroll';

export default{ 
  name:'DetailAnalyzeTable',
  components:{},
  props: {
    analysisData: {
      type: Object
    },
    inningData: {
      type: Object
    },
    maxInning: {
      type: Number
    },
    teamInfo: {
      type: String
    }
  },
  data() {
    return{
      sampleData: ''
    };
  },
  setup() {

  },
  created() {},
  mounted() {
    this.myScroll = new IScroll(this.$refs.data.querySelector('.move-zone'), {
      eventPassthrough: true,
      scrollX: true,
      scrollY: true,
      scrollbars: true,
      preventDefault: false,
    });

    document.querySelectorAll(".iScrollIndicator").forEach(el => {
      el.style.width = "55px";
      el.style.left = "270px";
    });
  },
  unmounted() {},
  methods: {},
  beforeUnmount() {
    // 컴포넌트가 해제되기 전에 iScroll 인스턴스를 제거하여 메모리 누수를 방지합니다.
    this.myScroll.destroy();
    this.myScroll = null;
  },
}
</script>