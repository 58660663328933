import axios from 'axios';

class NoticeService {
  // 공지 목록 조회하기
  async $getNoticeList(dataSc, txtSearch, pageSize, pageNo) {
    const result = axios.get('/Notice/noticelist', {
      params: {
        dataSc: dataSc
        , txtSearch: txtSearch
        , pageSize: pageSize
        , pageNo: pageNo
      }
    })
    return await result;
  }

  // 공지 조회하기
  async $getNotice(noticeSe) {
    const result = axios.get(`/Notice/getNotice/${noticeSe}`);
    return await result;
  }
}

export default NoticeService;