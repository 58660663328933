<template>
  <table>
    <colgroup>
      <col width="30px;">
      <col width="142px;">
      <col width="30px;">
      <col width="142px;">
    </colgroup>
    <tr>
      <th>현재이닝분포도</th>
      <td>
        <div class="s2i_point s2i_size4">
          <span class="s2i_ball" v-for="ball in innBallList" :key="ball" :style="`left:${ball.x}px; top:${ball.y}px;`" :g-id="ball.gId" :seq-no="ball.seqNo">
            <a :class="`ball_type${ball.color}_${ball.type}`"></a>
          </span>
        </div>
      </td>
      <th>초구</th>
      <td>
        <div class="s2i_point s2i_size4">
          <span class="s2i_ball" v-for="ball in firstBallList" :key="ball" :style="`left:${ball.x}px; top:${ball.y}px;`" :g-id="ball.gId" :seq-no="ball.seqNo">
            <a :class="`ball_type${ball.color}_${ball.type}`"></a>
          </span>
        </div>
      </td>
    </tr>
  </table>

  <table>
    <colgroup>
      <col width="30px;">
      <col width="142px;">
      <col width="30px;">
      <col width="142px;">
    </colgroup>
    <tr>
      <th>1S 이후</th>
      <td>
        <div class="s2i_point s2i_size4">
          <span class="s2i_ball" v-for="ball in oneStrikeBallList" :key="ball" :style="`left:${ball.x}px; top:${ball.y}px;`" :g-id="ball.gId" :seq-no="ball.seqNo">
            <a :class="`ball_type${ball.color}_${ball.type}`"></a>
          </span>
        </div>
      </td>
      <th>2S 이후</th>
      <td>
        <div class="s2i_point s2i_size4">
          <span class="s2i_ball" v-for="ball in twoStrikeBallList" :key="ball" :style="`left:${ball.x}px; top:${ball.y}px;`" :g-id="ball.gId" :seq-no="ball.seqNo">
            <a :class="`ball_type${ball.color}_${ball.type}`"></a>
          </span>
        </div>
      </td>
    </tr>
  </table>
</template>
<script>

import ReportService from "@/utils/service/reportService.js"

export default{ 
  name:'LocationRightView',
  components:{},
  data() {
    return{
      reportService : new ReportService(),

      innBallList: [],        // 현재이닝분포도
      firstBallList: [],      // 초구
      oneStrikeBallList: [],  // 1S이후
      twoStrikeBallList: [],  // 2S이후
    };
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    // 조건별 로케이션
    getConditionLocation(gameId, nowInfo){
      this.reportService.$getPitLocation(gameId, nowInfo.inn, nowInfo.tb, nowInfo.pId).then(result => {
        //console.log("getConditionLocation", result);

        this.innBallList = result.data.conditionLocationList[0].ballList || [];
        this.firstBallList = result.data.conditionLocationList[1].ballList || [];
        this.oneStrikeBallList = result.data.conditionLocationList[2].ballList || [];
        this.twoStrikeBallList = result.data.conditionLocationList[3].ballList || [];
      })
    },
  }
}
</script>
