<template>
  <table class="tb">
    <colgroup>
      <col width="100px">
      <col width="155px">
      <col width="">
      <col width="105px">
      <col width="105px">
      <col width="105px">
    </colgroup>
    <tr>
      <th>번호</th>
      <th>구분</th>
      <th>제목</th>
      <th>첨부</th>
      <th>상대팀</th>
      <th>장소</th>
    </tr>
    <tr v-for="interview in interviewList" :key="interview.RELEASE_SE">
      <td>{{interview.ROW_NU}}</td>
      <td>{{interview.SECTION_NM}}</td>
      <td class="title">
        <a href="#" @click.prevent="interviewDetail(interview.RELEASE_SE)">{{interview.TITLE_NM}}</a>
      </td>
      <td>
        <i v-if="interview.FILE_CK == 1" class="icon-file"></i>
      </td>
      <td>{{interview.VS_T_NM}}</td>
      <td>{{interview.S_NM}}</td>
    </tr>
    <tr v-if="interviewList.length == 0">
      <td colspan="6">
        검색 결과가 존재하지 않습니다.
      </td>
    </tr>
  </table>
</template>
<script>

export default{ 
  name:'InterviewListView',
  components:{},
  props:{
    interviewList: {
      type: Array
    }
  },
  data() {
    return{
      sampleData: ''
    };
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    interviewDetail(bdSe) {
      this.$router.push(`/interview/${bdSe}`);
    }
  }
}
</script>
