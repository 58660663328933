<template>
  <div class="box">
    <span class="date">{{ gameDate }}</span>
    <p class="tit-team">{{ gameInfo.awayNm }}<span>vs</span>{{ gameInfo.homeNm }}</p>
    <a class="btn-copy" href="" @click.prevent="copyContent">
      <img src="@/assets/images/contents/icon_copy.png" alt="copy" />
    </a>
  </div>
  <div class="box">
    <div class="tb-box">
      <div class="tb-scroll">
        <table class="tb-mini">
          <tr>
            <th>경기 이슈</th>
          </tr>
          <tr>
            <td ref="issueRef" v-html="issue"></td>
          </tr>
        </table>
      </div>
    </div>
  </div>	
</template>
<script>

import BroadcastService from "@/utils/service/broadcastService.js"
import Common from "@/utils/common.js"

export default{ 
  name:'DetailIssue',
  components:{},
  props: {
    gameInfo: {
      type: Object
    }
  },
  data() {
    return{
      broadcastService: new BroadcastService(),
      common: new Common(),
      gameDate: '',     // 경기 날짜
      //homeTeamName: '', // 홈팀
      //awayTeamName: '', // 원정팀
      issue: ''         // 경기 이슈
    };
  },
  watch: {
    'gameInfo.gameId': {
      handler(gameId) {
        this.getGameIssue(gameId)
      }
    }
  },
  setup() {},
  created() {},
  mounted() {
    //console.log('mount issue', this.gameInfo.gameId)
    this.getGameIssue(this.gameInfo.gameId)
  },
  unmounted() {},
  methods: {
    getGameIssue(gameId) {  // 경기 이슈
      //console.log('이슈', gameId)
      if(gameId === '') {
        console.log('없음')
      } else {
        this.gameDate = this.common.yyyyMMddDot(gameId.substring(0, 8));  // 경기이슈 경기 날짜

        this.broadcastService.$getGameIssue(gameId).then(result => {
          //console.log('성공', result)
          if(result.status === 200) {
            //this.homeTeamName = result.data.homeTeamName; // 홈팀
            //this.awayTeamName = result.data.awayTeamName; // 원정팀
            this.issue = result.data.FOCUS_CT;            // 경기 이슈
          } else {
            this.issue = '';
          }
        });
      }
    },
    copyContent() { // 복사
      navigator.clipboard.writeText(this.$refs.issueRef.textContent);
      alert('복사되었습니다.')
    }
  },
}
</script>
