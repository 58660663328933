<template>
  <div class="board keypoint">
    <div class="title">KEY POINT</div>

    <div class="box">
      <ul class="tab" id="ulTab">
        <li class="on">
          <a href="#" @click.prevent="selectTab" section="all">전체</a>
        </li>
        <li><a href="#" @click.prevent="selectTab" section="run">득점</a></li>
        <li>
          <a href="#" @click.prevent="selectTab" section="homerun">홈런</a>
        </li>
        <li>
          <a href="#" @click.prevent="selectTab" section="change">교체</a>
        </li>
      </ul>
      <div class="tab-box">
        <a class="btn-next keyPointInning" style="z-index: 1"
          ><img src="@/assets/images/contents/icon_next.png" alt="옆으로"
        /></a>
        <KeyPointInn
          :component-id="'keyPointInning'"
          :select-inning="keyPointData.selectInnNo"
          :inning-list="keyPointData.innList"
          @selectInnBox="changeInn"
        ></KeyPointInn>
        <KeyPointTable :table-data="keyPointData.tableDataArr" :select-inning="keyPointData.selectInnNo"></KeyPointTable>
      </div>
    </div>

    <div class="box">
      <a v-if="!this.intervalObj.isKeyPointRun" class="btn-stop" @click.prevent="checkStartIntervalButton"
        ><img src="@/assets/images/contents/icon_play.png" alt="재생"/>
      </a>
      <a v-else class="btn-stop" @click.prevent="checkStopIntervalButton">
        <img src="@/assets/images/contents/icon_stop.png" alt="일시중지"/>
      </a>
      <ul class="tab">
        <li class="on">
          <a href="#" @click.prevent="selectTab" section="broadAll"
            >전체 중계</a
          >
        </li>
        <li>
          <a href="#" @click.prevent="selectTab" section="broadSummary"
            >요약 중계</a
          >
        </li>
      </ul>
      <div class="tab-box">
        <a class="btn-next livetextInning" style="z-index: 1"
          ><img src="@/assets/images/contents/icon_next.png" alt="옆으로"
        /></a>
        <KeyPointInn
          :component-id="'livetextInning'"
          :select-inning="liveTextData.selectInnNo"
          :inning-list="liveTextData.innList"
          @selectInnBox="changeInn"
        ></KeyPointInn>
        <KeyPointTable :table-data="liveTextData.tableDataArr" :select-inning="liveTextData.selectInnNo"></KeyPointTable>
      </div>
    </div>
  </div>
</template>
<script>
import BroadcastService from "@/utils/service/broadcastService.js";
import Common from "@/utils/common.js"
import KeyPointInn from "@/components/Broadcast/KeyPoint/KeyPointInn.vue";
import KeyPointTable from "@/components/Broadcast/KeyPoint/KeyPointTable.vue";

export default {
  name: "KeyPointView",
  components: { KeyPointInn, KeyPointTable },
  props: {
    gameInfo: {
      type: Object,
    },
    seasonId: {
      type: Number,
    },
    gameDate: {
      type: String,
    },
    intervalObj: {
      type: Object,
    },
  },
  watch: {
    "gameInfo.gameId"() {
      // gameId 변화를 감지하여 업데이트
      //this.keyPointData.selectCategory = '0';
      //this.liveTextData.selectCategory = 'broadAll';
      this.keyPointData.selectInnNo = 0;
      this.liveTextData.selectInnNo = 0;
      this.keyPointData.lastSearchSeqNo = 1000;
      this.liveTextData.lastSearchSeqNo = 1000;

      //this.$emit('updateKeyPointRun', false);
      this.getAllKeyPointData();
      //this.initCheckInterval();
    },
    //$route(to, from) {
    //  // 페이지 이동이 일어나면 인터벌 삭제
    //  if (to.path != from.path) {
    //    this.stopInterval();
    //  }
    //},
  },
  data() {
    return {
      broadcastService: new BroadcastService(),
      common: new Common(),

      // KEYPOINT의 파라미터와 리턴값 객체
      keyPointData: {
        selectCategory: "0", // KEYPOINT(상단탭) 카테고리 (전체 0 / 득점 1 / 홈런 2 / 교체 3)
        selectInnNo: 0, // 선택 이닝
        innList: [], // 검색 결과 이닝 리스트
        tableDataArr: [], // 검색 결과 KEYPOINT 데이터
        lastSearchSeqNo: 1000,  // 최근 검색했을 때 가장 최근의 Seq
      },
      liveTextData: {
        selectCategory: "broadAll", // 중계(하단탭) 카테고리 (전체중계 broadAll / 요약중계 broadSummary)
        selectInnNo: 0, // 선택 이닝
        innList: [], // 검색 결과 이닝 리스트
        tableDataArr: [], // 검색 결과 중계 데이터
        lastSearchSeqNo: 1000,  // 최근 검색했을 때 가장 최근의 Seq
      },

      // 실시간 중계 재생/정지 interval
      isKeyPointLive: false,
      isTodayGame: null, // 금일 게임 여부

    };
  },
  setup() {},
  created() {
    this.getAllKeyPointData();
  },
  mounted() {},
  unmounted() {},
  methods: {
    selectTab(e) {
      var sections = e.target.parentNode.parentNode.children;
      //console.log(e.target.getAttribute("section")); // 탭 속성

      // API에 접근 가능한 데이터로 바꿔서 저장
      switch (e.target.getAttribute("section")) {
        case "all":
          this.keyPointData.selectCategory = 0;
          this.getKeyPointData();
          break;
        case "run":
          this.keyPointData.selectCategory = 1;
          this.getKeyPointData();
          break;
        case "homerun":
          this.keyPointData.selectCategory = 2;
          this.getKeyPointData();
          break;
        case "change":
          this.keyPointData.selectCategory = 3;
          this.getKeyPointData();
          break;
        case "broadAll":
        case "broadSummary":
          this.liveTextData.selectCategory = e.target.getAttribute("section");
          this.getLiveTextData();
          break;
      }

      for (var i = 0; i < sections.length; i++) {
        sections[i].classList.remove("on");
      }
      e.target.parentNode.classList.add("on");
    },
    changeInn(id, innNo) {
      if (id == "keyPointInning") {
        // 상단 탭 이닝
        if (innNo == "all") {
          this.keyPointData.selectInnNo = 0;
        } else {
          this.keyPointData.selectInnNo = innNo;
        }
        // 데이터 업데이트
        this.getKeyPointData();
      } else if (id == "livetextInning") {
        // 하단 탭 이닝
        if (innNo == "all") {
          this.liveTextData.selectInnNo = 0;
        } else {
          this.liveTextData.selectInnNo = innNo;
        }
        // 데이터 업데이트
        this.getLiveTextData();
      }
    },

    // 데이터 로드 관련 Method
    getAllKeyPointData() {
      this.getKeyPointData();
      this.getLiveTextData();
    },
    getKeyPointData() {
      const startInnNo = 0;
      const endInnNo = this.intervalObj.isLive ? 1000 : this.keyPointData.lastSearchSeqNo;

      try {
        if (this.gameInfo.gameId) {
          // KEYPOINT 데이터 가져오기
          this.broadcastService
            .$getKeyPoint(
              this.keyPointData.selectCategory,
              startInnNo, 
              endInnNo,
              this.keyPointData.selectInnNo,
              this.seasonId,
              this.gameInfo.gameId
            )
            .then((result) => {
              //console.log(result);
              let data = result.data;
  
              if (data.code == "100") {
                this.keyPointData.tableDataArr = data.keyPointList;
                this.keyPointData.innList = data.innList;
                this.keyPointData.lastSearchSeqNo = data.maxSeqNo;
              }
            });
        } 
      }
      catch (e) {
        //console.error(e);
        // 해당 날짜에 게임이 없는 경우
        this.keyPointData.tableDataArr = [];
        this.keyPointData.innList = [
          {
            "innNo": 0,
            "innText": "전체"
          },
          {
            "innNo": 1,
            "innText": "1회"
          },
          {
            "innNo": 2,
            "innText": "2회"
          },
          {
            "innNo": 3,
            "innText": "3회"
          },
          {
            "innNo": 4,
            "innText": "4회"
          },
          {
            "innNo": 5,
            "innText": "5회"
          },
          {
            "innNo": 6,
            "innText": "6회"
          },
          {
            "innNo": 7,
            "innText": "7회"
          },
          {
            "innNo": 8,
            "innText": "8회"
          },
          {
            "innNo": 9,
            "innText": "9회"
          }
        ];
      }
    },
    getLiveTextData() {
      const startInnNo = 0;
      const endInnNo = this.intervalObj.isLive ? 1000 : this.liveTextData.lastSearchSeqNo;

      if (this.gameInfo.gameId) {
        try {
          if (this.liveTextData.selectCategory == "broadAll") {
            // 전체 중계 데이터 가져오기
            this.broadcastService
              .$getLivetextFull(
                startInnNo,
                endInnNo,
                this.liveTextData.selectInnNo,
                this.gameInfo.gameId
              )
              .then((result) => {
                //console.log(result);
                let data = result.data;

                if (data.code == "100") {
                  this.liveTextData.tableDataArr = data.livetextFullList;
                  this.liveTextData.innList = data.innList;
                  this.liveTextData.lastSearchSeqNo = data.maxSeqNo;
                }
              });
          } else if (this.liveTextData.selectCategory == "broadSummary") {
            // 요약 중계 데이터 가져오기
            this.broadcastService
              .$getLivetextSimple(
                startInnNo,
                endInnNo,
                this.liveTextData.selectInnNo,
                this.gameInfo.gameId
              )
              .then((result) => {
                //console.log(result);
                let data = result.data;

                if (data.code == "100") {
                  this.liveTextData.tableDataArr = data.livetextSimpleList;
                  this.liveTextData.innList = data.innList;
                  this.liveTextData.lastSearchSeqNo = data.maxSeqNo;
                }
              });
          }
        } catch (e) {
          // 해당 날짜에 게임이 없는 경우
          this.liveTextData.tableDataArr = [];
          this.liveTextData.innList = [
            {
              "innNo": 0,
              "innText": "전체"
            },
            {
              "innNo": 1,
              "innText": "1회"
            },
            {
              "innNo": 2,
              "innText": "2회"
            },
            {
              "innNo": 3,
              "innText": "3회"
            },
            {
              "innNo": 4,
              "innText": "4회"
            },
            {
              "innNo": 5,
              "innText": "5회"
            },
            {
              "innNo": 6,
              "innText": "6회"
            },
            {
              "innNo": 7,
              "innText": "7회"
            },
            {
              "innNo": 8,
              "innText": "8회"
            },
            {
              "innNo": 9,
              "innText": "9회"
            }
          ]
        }
      }
    },
    setIsTodayGame() {
      const nowDate = this.common.DateObjToString(new Date()); // yyyyMMdd
      this.isTodayGame = this.gameDate == nowDate;
    },
    checkStartIntervalButton() {
      if (this.gameInfo.gameId !== '' && this.gameInfo.sectionId == '2') {
        this.$emit('updateKeyPointRun', true);
      }
      // if (this.gameInfo.gameId !== '') {
      //   this.setIsTodayGame();
      
      //   // 경기가 있으면서 오늘 경기인 경우
      //   if (this.isTodayGame) {
      //     this.$emit('updateKeyPointRun', true);
      //   }
      //   else {
      //    alert('금일 경기가 아니거나 경기가 종료되었습니다. ');
      //   }
      // }
      //else {
      //  alert('현재 날짜에 해당하는 경기가 존재하지 않습니다.');
      //}
    },
    checkStopIntervalButton() {
      this.$emit('updateKeyPointRun', false);
    }
    
    
  },
};
</script>
<style scoped>
</style>