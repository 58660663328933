<template>
  <div class="scroll-wrap sub-tab">
    <ul>
      <swiper
        :navigation="{
          nextEl: `.btn-next.${this.componentId}`,
        }"
        :modules="modules"
        :slides-per-view="10"
      >
        <swiper-slide v-for="item in inningList" :key="item.innNo">
          <li :class="{ on: selectInning == item.innNo }">
            <a @click.prevent="selectInn" :data="item.innNo">{{
              item.innText
            }}</a>
          </li>
        </swiper-slide>
      </swiper>
    </ul>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

export default {
  name: "KeyPointInn",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    componentId: {
      Type: String,
    },
    selectInning: {
      Type: Number,
    },
    inningList: {
      Type: Array,
    },
  },
  data() {},
  setup() {
    return {
      modules: [Navigation],
    };
  },
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    selectInn(e) {
      const selectedInnNo = e.target.getAttribute("data");

      //console.log(this.componentId); // 표 위/아래
      //console.log(selectedInnNo); // 이닝

      const onListElements = e.target.parentNode.parentNode.parentNode.querySelectorAll("li.on");
      //console.log(onListElements); // 상단 탭
      onListElements.forEach((element) => {
        element.classList.remove("on");
      });

      e.target.parentNode.classList.add("on");

      this.$emit("selectInnBox", this.componentId, selectedInnNo);
    },
  },
};
</script>
