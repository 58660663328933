<template>
  <table>
    <colgroup>
      <col width="30px;">
    </colgroup>
    <tr>
      <th>우타자</th>
      <td>
        <ul>
          <li v-for="right in rightList" :key="right">
            <div class="s2i_point s2i_size4">
              <span class="s2i_ball" v-for="ball in right.ballList" :key="ball" :style="`left:${ball.x}px; top:${ball.y}px;`" :g-id="ball.gId" :seq-no="ball.seqNo">
                <a :class="`ball_type${ball.color}_${ball.type}`"></a>
              </span>
            </div>
            <p class="tit">{{right.ballTitle}} ({{right.ballList.length}})</p>
          </li>
          <li v-for="index in 5 - rightList.length" :key="index">
            <div></div>
            <p class="tit"></p>
          </li>
        </ul>
      </td>
    </tr>
  </table>

  <table>
    <colgroup>
      <col width="30px;">
    </colgroup>
    <tr>
      <th>좌타자</th>
      <td>
        <ul>
          <li v-for="left in leftList" :key="left">
            <div class="s2i_point s2i_size4">
              <span class="s2i_ball" v-for="ball in left.ballList" :key="ball" :style="`left:${ball.x}px; top:${ball.y}px;`" :g-id="ball.gId" :seq-no="ball.seqNo">
                <a :class="`ball_type${ball.color}_${ball.type}`"></a>
              </span>
            </div>
            <p class="tit">{{left.ballTitle}} ({{left.ballList.length}})</p>
          </li>
          <li v-for="index in 5 - leftList.length" :key="index">
            <div></div>
            <p class="tit"></p>
          </li>
        </ul>
      </td>
    </tr>
  </table>
</template>
<script>

import ReportService from "@/utils/service/reportService.js"

export default{ 
  name:'LocationLeftView',
  components:{},
  data() {
    return{
      reportService : new ReportService(),

      rightList:[],
      leftList:[],
    };
  },
  setup() {},
  created() {},
  mounted() {
    //this.getBatDirecLocation();
  },
  unmounted() {},
  methods: {
    // 우타자/좌타자 별 로케이션
    getBatDirecLocation(gameId, nowInfo){
      this.reportService.$getPitLocation(gameId, nowInfo.inn, nowInfo.tb, nowInfo.pId).then(result => {
        //console.log("BatDirecLocation", result);
        
        this.rightList = result.data.locationList.slice(0,5) || [];
        this.leftList = result.data.locationList.slice(5) || [];
      })
    },
  }
}
</script>
