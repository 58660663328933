<template>
	<div id="wrap" class="wrap login">
		<!-- container -->
		<div class="container">
			<div class="contents">
				<div class="login-wrap">
					<img src="@/assets/images/contents/icon_login.png" alt="NC 다이노스 D-Base" />
					<div class="login-box">
						<ul class="login-input">
							<li class="id">
								<input type="text" id="id" placeholder="아이디" v-model="txtId" />
							</li>
							<li class="pw">
								<input type="password" id="pw" placeholder="패스워드" v-model="txtPassword" @keyup.enter="btnLogin()" />
							</li>
						</ul>
						<a href="#" class="btn-login" @click.prevent="btnLogin()">로그인</a>
						<p class="id-save">
							<input type="checkbox" id="id_save" v-model="idSave" />
							<label for="id_save">아이디 저장</label>
						</p>
					</div>
				</div>
			</div>
		</div>
		<!-- //container -->
	</div>
	<PopupView v-if="isPopupShow" @close-popup="isPopupShow = false" @go-event="goEvent" :popup-info="this.popup"></PopupView>
	<PopupView v-if="isUpdatePopupShow" @close-popup="updatePopupClose();" @go-event="goEvent" :popup-info="this.updatePopup"></PopupView>
</template>
<script>

import PopupView from '@/components/Layout/PopupView.vue';

import Common from "@/utils/common.js"
import MemberService from "@/utils/service/memberService.js"

export default{ 
  name:'LoginView',
  components:{PopupView},
  data() {
    return{
      common: new Common(),
      memberService: new MemberService(),

      txtId: '',				// 아이디
      txtPassword: '',			// 비밀번호
      idSave: false,			// 아이디 저장

      // 팝업 관련 data
      isPopupShow: false,
      popup:{
        content: '',         // 팝업창 문구
        buttonType: '',    // 1 : 버튼 1개(확인), 2: 버튼 2개 (취소, 확인)
        funcType: ''      // insert, update, delete
		},

      // 비밀번호 변경 팝업 관련 data
      isUpdatePopupShow: false,
      updatePopup:{
        content: '',         // 팝업창 문구
        buttonType: '',    // 1 : 버튼 1개(확인), 2: 버튼 2개 (취소, 확인)
        funcType: ''      // insert, update, delete
      },
    };
  },
  setup() {},
  created() {},
  mounted() {
		let $saveId = this.common.getCookie('Cookie_Id');	// 쿠키에 저장한 id 값
		
		if($saveId){
			this.txtId = $saveId;
			this.idSave = true;
		}
	},
  unmounted() {},
  methods: {
	async btnLogin() {
		if(this.isPopupShow){
			this.isPopupShow = false;
		} else {
			const result = await this.memberService.$login(this.txtId, this.txtPassword, this.idSave);
			if (result.data.code == "100") {
				// NC 홍보팀 계정 (nc1)은 IP 체크 안함
				if (JSON.parse(result.data.userInfo).U_ID != 'nc1') {
					this.memberService.$ipCheck().then(response => {
						if (response.data.code == '100') {
							if (result.data.updateDt >= 90) {	//비밀번호 변경한지 90일 이상
								this.isUpdatePopupShow = true;
								this.updatePopup = {
									content: '90일마다 비밀번호 변경이 필요합니다. 구단 홍보팀에 문의해주세요.'
									, buttonType: 1
								};
							} else {
								this.$router.push('/broadcast/livetext');
							}
						} else {
							this.isPopupShow = true;
							this.popup = {
								content: response.data.msg
								, buttonType: 1
							};
						}
					})
				} else {
					if (result.data.updateDt >= 900) {	//비밀번호 변경한지 90일 이상
						this.isUpdatePopupShow = true;
						this.updatePopup = {
							content: '90일마다 비밀번호 변경이 필요합니다. 구단 홍보팀에 문의해주세요.'
							, buttonType: 1
						};
					} else {
						this.$router.push('/broadcast/livetext');
					}

					alert(`본 서비스에서 제공하는 정보의 저작 권리는 NC 다이노스와 스포츠투아이㈜에게 있으며,\nNC 다이노스 관련 미디어취재를 위해서 활용할 수 있습니다.\n이를 개인적, 상업적 용도로 (정보 열람, 자료 다운로드, 화면 캡처, 제 3자 제공 등) 사용하는 것은 엄격히 제한됩니다`);
				}
			} else if (result.data.code == "200") {
				this.isPopupShow = true;
				this.popup = {
					content: '비밀번호가 5회 이상 틀렸습니다. 구단 홍보팀에 문의해주세요.'
					, buttonType: 1
				};
			} else {
				this.isPopupShow = true;
				this.popup = {
					content: '아이디 또는 비밀번호를 다시 입력해주세요.'
					, buttonType: 1
				};
			}
		}
    },
	updatePopupClose() {
		this.isUpdatePopupShow = false;
		this.$router.push('/broadcast/livetext');
	}
  }
}
</script>
