import axios from 'axios';

class InterviewService {
  // 카테고리 목록 조회하기
  async $getSectionList() {
    const result = axios.get(`/Interview/sectionlist`);
    return await result;
  }

  // 인터뷰 목록 조회하기
  async $getInterviewList(gameDt, sectionId, dataSc, txtSearch, pageSize, pageNo) {
    const result = axios.get('/Interview/interviewlist', {
      params: {
        gameDt: gameDt
        , sectionId: sectionId
        , dataSc: dataSc
        , txtSearch: txtSearch
        , pageSize: pageSize
        , pageNo: pageNo
      }
    })
    return await result;
  }

  // 인터뷰 조회하기
  async $getInterview(interviewSe) {
    const result = axios.get(`/Interview/getInterview/${interviewSe}`);
    return await result;
  }
}

export default InterviewService;