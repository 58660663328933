<template>
  <div class="board date" :class="{'in-dh':isDoubleHeader}">
    <div class="box">
      <DayBoxView :is-print="this.isPrint" :game-date="gameDate" @gameDate="changeDate"></DayBoxView>

      <p class="tit-team">{{this.gameInfo.awayNm}}<span>vs</span>{{this.gameInfo.homeNm}}</p>
      <DoubleHeaderView v-show="isDoubleHeader" :double-header="this.gameInfo.headerNo" @doubleHeader="changeHeader"></DoubleHeaderView>
      <ul class="list">
        <li>
          <span>이닝</span>
          <select id="selInn" v-model="selectboxData.inn" sel-data="inn" @change="changeSelect" >
            <option v-for="index in maxInning" :key="index" :value="index" :selected="selectboxData.inn === index" >{{index}}</option>
          </select>
        </li>
        <li>
          <span>초말</span>
          <select id="selTb" v-model="selectboxData.tb" sel-data="tb" @change="changeSelect">
            <option value="T">초</option>
            <option value="B">말</option>
          </select>
        </li>
      </ul>
    </div>
    <div class="box in-img">
      <img src="@/assets/images/contents/img_footnote.png" alt="구질 - 직, 커, 우슬, 좌슬, 체, 포, 싱, 투, 컷, 너 / 결과 - 스트, 볼, 스윙, 파울, 안타, 범타">
    </div>
  </div>
</template>
<script>

import DayBoxView from '@/components/Common/DayBoxView.vue';
import DoubleHeaderView from '@/components/Common/DoubleHeaderView.vue';

import Common from "@/utils/common.js"
import CommonService from "@/utils/service/commonService.js"
import ReportService from "@/utils/service/reportService.js"

export default{ 
  name:'DateView',
  components:{DayBoxView, DoubleHeaderView},
  emits: ['gameInfo', 'selectbox', 'initInterval'],
  props:{
    isPrint: {
      type: Boolean
    },
  },

  data() {
    return{
      common: new Common(),
      commonService : new CommonService(),
      reportService : new ReportService(),

      isDoubleHeader: false,   // true: 더블헤더 O, false: 더블헤더 X
      gameInfo:{headerNo:0, gameId: '', awayNm: '', homeNm: '', sectionId: ''},         // 해당 날짜 경기 정보
      
      gameDate: '',

      maxInning: 0, // 최대 이닝
      selectboxData: {},
    };
  },
  setup() {},
  created() {},
  mounted() {
    this.gameDate = this.common.yyyyMMdd(new Date(),'')
  },
  unmounted() {},
  methods: {
    // 달력 변경
    changeDate(data){
      this.gameDate = data;
      this.getGameInfo(0);
    },

    initData(){
      this.gameDate = this.common.yyyyMMdd(new Date(),'')
      this.getGameInfo(0);
    },

    // 더블헤더 변경
    changeHeader(data){
      this.headerNo = data;
      this.getGameInfo(data);
    },

    // selectbox 변경
    changeSelect(){
      this.$emit('selectbox', this.selectboxData);
      this.$emit('initInterval', true);
    },

    // 경기 정보 가져오기
    getGameInfo(headerNo){
      this.commonService.$getGameInfo(this.gameDate).then(result => {
        //console.log('getGameInfo', result)
        let data = result.data;

        if(data.code == '100'){
          let item = data.gameList || [];

          // 경기 있는 날
          if(data.GAME_CN > 0){
            let index;

            if(data.DOUBLE_CK == 1){
              this.isDoubleHeader = true;
              index = item[1].SECTION_ID == '2' ? 1 : 0;

              //this.gameInfo.headerNo = headerNo == 0 ? 1 : headerNo;
              this.gameInfo.headerNo = headerNo == 0 ? item[index].HEADER_NO : headerNo;
            } else {
              this.isDoubleHeader = false;
              index = 0;
              this.gameInfo.headerNo = item[0].HEADER_NO;
            }

            this.gameInfo.gameId = `${item[index].G_DT}${item[index].AWAY_ID}${item[index].HOME_ID}${this.gameInfo.headerNo}`;
            this.gameInfo.awayNm = `${item[index].AWAY_NM}`;
            this.gameInfo.homeNm = `${item[index].HOME_NM}`;
            this.gameInfo.sectionId = `${item[index].SECTION_ID}`;

            this.$emit('gameInfo', this.gameInfo);
            this.getNowInnTB();
          } else {  // 경기 없는 날
            this.gameInfo = {gameId: '', awayNm: '-', homeNm: '-', sectionId: ''};
            this.isDoubleHeader = false;
           // this.maxInning = 1;
            //this.selectboxData = {inn: 1, tb: 'T'}
            this.selectboxData = {};
            this.$emit('gameInfo', this.gameInfo);
            this.$emit('selectbox', this.selectboxData);
          }
        }
      })
    },

    // 이닝, 초말 설정
    getNowInnTB(){
      this.reportService.$getNowInfo(this.gameInfo.gameId).then(result => {

        if(result.data.code == '100'){
          //console.log(result.data)
          let $maxInning = parseInt(result.data.MAX_INN_NO);
          let $currInning = parseInt(result.data.CURR_INN_NO);
          let $nowTb = result.data.TB_SC;
          let $pId = result.data.PIT_P_ID;
          let $maxPa = result.data.PA_ORDER_NO;   // 현재 타석 강조 효과
          
          this.maxInning = $maxInning; 
          this.selectboxData = {inn: $currInning, tb: $nowTb, pId: $pId, maxPa: $maxPa};

          this.$emit('selectbox', this.selectboxData);
        }
      })
    }
  }
}
</script>
