<template>
  <div class="board detail" :class="$route.params.detail == 'livetext' ? '' : $route.params.detail">
    <DetailTop></DetailTop>
    <DetailRecord ref="livetext" v-if="$route.params.detail =='livetext'" :game-info="gameInfo"></DetailRecord>
    <DetailAnalyze ref="analyze" v-if="$route.params.detail =='analyze'" :game-info="gameInfo"></DetailAnalyze>
    <DetailHomerun ref="homerun" v-if="$route.params.detail =='homerun'" :game-info="gameInfo"></DetailHomerun>
    <DetailIssue ref="issue" v-if="$route.params.detail =='issue'" :game-info="gameInfo"></DetailIssue>
  </div>		
</template>
<script>

import DetailTop from '@/components/Broadcast/Detail/DetailTop.vue';
import DetailRecord from '@/components/Broadcast/Detail/DetailRecord.vue';
import DetailAnalyze from '@/components/Broadcast/Detail/DetailAnalyze.vue';
import DetailHomerun from '@/components/Broadcast/Detail/DetailHomerun.vue';
import DetailIssue from '@/components/Broadcast/Detail/DetailIssue.vue';

export default{ 
  name:'DetailView',
  components:{DetailTop, DetailRecord, DetailAnalyze, DetailHomerun, DetailIssue},
  props: {
    gameInfo: {
      type: Object
    }
  },
  data() {
    return{
      sampleData: ''
    };
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    getAllDetailData() {
      const nowGameId = this.gameInfo.gameId;

      switch (this.$route.params.detail) {
        case 'livetext':
          this.$refs.livetext.getRecordData(nowGameId);
          break;
        case 'analyze':
          this.$refs.analyze.getAnalysisData();
          break;
        case 'homerun':
          this.$refs.homerun.getHomerunList();
          break;
        case 'issue':
          this.$refs.issue.getGameIssue(nowGameId);
          break;
      }
    }
  }
}
</script>
