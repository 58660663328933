<template>
  <div class="down-box">
    <a class="btn down" href="" v-show="section1.fileLk != null" @click.prevent="downFile(section1)">
      주요행사 & 큐시트
    </a>
    <a class="btn down" href="" v-show="section2.fileLk != null" @click.prevent="downFile(section2)">
      KBO 기록/순위/엔트리
    </a>
  </div>
</template>

<style src="@/assets/css/edit.css"></style>

<script>
import axios from 'axios';
import GameNoteService from "@/utils/service/gamenoteService.js"

export default{ 
  name: 'GameNote',
  components: {},
  data() {
    return{
      gameNoteService: new GameNoteService(),
      // section1FileLink: null,
      // section2FileLink: null,
      // section1FileName: null,
      // section2FileName: null,
      section1: {},
      section2: {},
      gameDate: '',
    };
  },
  setup() {},
  created() {},
  mounted() {
  },
  unmounted() {},
  methods: {
    getHomeGameFileData(gameId, gameDate) { // 홈경기 데이터 호출
      if(gameId.length > 0){
        this.gameNoteService.$getHomeGameFile(gameId).then(result => {
          this.section1 = {};
          this.section2 = {};

          if (result.status === 200) {
            //console.log('gamenote', gameId, result.data)
            result.data.forEach(fileObj => {
              if (fileObj.SECTION_ID == 1) {
                this.section1.fileLk = fileObj.FILE_LK;
                this.section1.fileNm = fileObj.FILE_NM;
                this.section1.gameDt = fileObj.G_DT;
              } else if (fileObj.SECTION_ID == 2){
                this.section2.fileLk = fileObj.FILE_LK;
                this.section2.fileNm = fileObj.FILE_NM;
                this.section2.gameDt = fileObj.G_DT;
              }
            });
          }
        });
      } else {
        this.section1 = {};
        this.section2 = {};
      }
      
      this.gameDate = gameDate;
    },

    // 첨부파일 다운로드
    downFile(file) {
      const fileUrl = `/File/download`; // API 엔드포인트 URL

      axios.get(fileUrl, {
        params:{
          file: file.fileLk
        },
        responseType: 'blob', // 파일 다운로드를 위해 responseType을 'blob'으로 설정
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: [response.data.type] }));  
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', file.fileNm); // 다운로드할 파일명 설정
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error('Failed to download file:', error);
      });
    }
  }
}
</script>
