<template>
	<header>
		<div class="top">
			<div class="wrap">
				<ul class="nav-tab">
					<li>
						<a href="http://dfoto.i234.me:8080/photo" target="_blank">사진서버</a>
					</li>
					<li>
						<a href="https://www.ncdinos.com/" target="_blank">홈페이지</a>
					</li>
					<li>
						<a href="https://www.youtube.com/@ncdinos" target="_blank">엔튜브</a>
					</li>
					<li>
						<a href="http://2istats.sports2i.com/" target="_blank">2iSTATS</a>
					</li>
					<li>
						<a href="https://www.koreabaseball.com/" target="_blank">KBO</a>
					</li>
				</ul>
				<div class="user-info">
					<i class="icon-login"></i>
					<b>{{session.U_NM}}</b> 님께서 사용중이십니다.
					<span>{{currentTime}}</span>
					<a href="#" class="btn-logout" @click.prevent="btnLogout()">
						LOGOUT
					</a>
				</div>
			</div>
		</div>
		<div class="gnb">
			<div class="wrap">
				<a class="logo" href="/broadcast/livetext">
					<img src="@/assets/images/contents/logo.png" alt="NC다이노스" />
				</a>
				<ul class="menu">
					<li :class="{on: this.$route.name == 'broadcast'}">
						<router-link to="/broadcast/livetext">MAIN</router-link>
					</li>
					<li :class="{on: this.$route.name == 'report'}"  v-if="this.userAuth.REPORT">
						<router-link to="/report" @click="this.$emit('init', true)">벤치리포트</router-link>
					</li>
					<li :class="{on: this.$route.name == 'gamenote'}" v-if="this.userAuth.GAMENOTE">
						<router-link to="/gamenote">게임노트</router-link>
					</li>
					<li :class="{on: this.$route.name.indexOf('interview') &gt; -1}" v-if="this.userAuth.INTERVIEW">
						<router-link to="/interview" @click="this.$emit('init', true)">RELEASE</router-link>
					</li>
					<li :class="{on: this.$route.name.indexOf('notice') &gt; -1}" v-if="this.userAuth.NOTICE">
						<router-link to="/notice" @click="this.$emit('init', true)">NOTICE</router-link>
					</li>
					<li :class="{on: this.$route.name == 'calculation'}" v-if="this.userAuth.CAL">
						<router-link to="/calculation">계산</router-link>
					</li>
				</ul>
			</div>
		</div>
	</header>
</template>
<script>

import moment from 'moment';    // npm install moment
import 'moment/locale/ko'       // 한글 사용 위함
import MemberService from '@/utils/service/memberService';
import CommonService from '@/utils/service/commonService';

export default{ 
  name:'HeaderView',
  components:{},
	emits:['init', 'open-popup'],
  data() {
    return{
			memberService: new MemberService()
			, commonService: new CommonService()
      , currentTime: new Date()

			, session: {}
			, writingCnt: 0

			, userAuth: {}
    };
  },
  setup() {},
  created() {},
  mounted() {
		this.getSession();
		this.checkUserAuth();
    this.updateTime();
		this.checkNewWriting(true);
    setInterval(this.updateTime, 1000); // 1초마다 작업 실행
    //setInterval(this.getSession, 60000); // 60초마다 작업 실행
		//setInterval(this.getSession, 1000); // 60초마다 작업 실행
		setInterval(this.checkNewWriting, 10000); // 1초마다 작업 실행

	},
  unmounted() {},
  methods: {
		// 로그아웃
    async btnLogout() {
			await this.memberService.$logout();
      this.$router.replace('/')
		},

		// 시간 업데이트
    updateTime() {
      this.currentTime = moment(this.dateTime).format('YYYY-MM-DD A hh:mm:ss');
    },

		getSession(){
			this.memberService.$session().then(response => {
				//console.log('getSession', response.data)
        if(response.data.length > 0){
          this.session = JSON.parse(response.data);

					// ip 확인
					this.getIpCheck();
        } else {
          this.btnLogout();
          this.$router.replace('/');
        }
      })
    },

		// ip 확인
		getIpCheck(){
			this.memberService.$ipCheck().then(response => {
				if(response.data.code == '200'){
					this.btnLogout();
					this.$router.replace('/');
				}
			})
		},

		// 새 글 확인
		checkNewWriting(ckFirst){
			this.commonService.$getWritingList().then(response => {
				//console.log(ckFirst, this.writingCnt, response.data.totalCnt)
				if(ckFirst) {
					this.writingCnt = response.data.totalCnt;
				} else {
					if(this.writingCnt < response.data.totalCnt){
						let division = response.data.writingList[0].DIVISION == "NOTICE" ? 'notice' : 'interview';
						this.$emit('open-popup', {seqNo:response.data.writingList[0].BD_SE, section:division, content: `[${response.data.writingList[0].SECTION_NM}] ${response.data.writingList[0].TITLE_NM} 이(가) 등록되었습니다.`, buttonType: 2});
						this.writingCnt = response.data.totalCnt;
					}
				}
				//console.log(response)
			})
		},

		// 탭 권한 확인
		checkUserAuth(){
			this.commonService.$userAuth().then(response => {
				//console.log(response);
				this.userAuth = response.data.objAuth;
			})
		}
	}
}
</script>
