<template>
  <template v-if="isSelect">
    <select v-model="pitcher" @change="changeSelect">
      <option value="0">투수</option> 
      <option :value="item.P_ID" :key="index" v-for="(item, index) in pitcherList">{{ item.P_NM }}</option>  
    </select>
  </template>
  <template v-else>
    <select v-model="pitcher" @change="changeSelect">
      <option value="0">투수</option> 
    </select>
  </template>
</template>

<script>

import BroadcastService from "@/utils/service/broadcastService.js"

export default{ 
  name: 'playerList',
  components: {},
  props: {
    gameInfo: {
      type:Object
    },
    teamSc: {
      type: String
    },
    dataSection: {
      type: String
    }
  },
  data() {
    return{
      broadcastService: new BroadcastService(),
      isSelect: false, // selectbox 유무
      pitcher: '0',    // 투수
      pitcherList: [],  // 투수 리스트
      teamId: '',
      gameId: ''
    };
  },
  watch: {
    'gameInfo.gameId': {
      handler(gameId) {
        this.teamId = (this.teamSc === 'T' ? gameId.substr(8, 2) : gameId.substr(10, 2));
        this.gameId = gameId;
        //console.log(this.teamSc, '투수 와치', gameId, this.teamId)
        this.getPitcherList(gameId);
      }
    },
    'dataSection': {
      handler() {
        this.getPitcherList(this.gameId);
      }
    },
  },
  setup() {},
  created() {},
  mounted() {
    this.teamId = (this.teamSc === 'T' ? this.gameInfo.gameId.substr(8, 2) : this.gameInfo.gameId.substr(10, 2));
    //console.log('투수 마운트', this.gameInfo, this.teamId)
    this.getPitcherList(this.gameInfo.gameId)
  },
  unmounted() {},
  methods: {
    getPitcherList(gameId) {  // 투수 리스트
      //console.log('투수리스트', this.isSelect, gameId)
      this.pitcher = '0';
      if(gameId === '') {
        this.isSelect = false;
      } else {
        this.broadcastService.$getPitcherList(gameId, this.teamId, this.dataSection).then(result => {
        if(result.status === 200) {
          this.isSelect = true;
          this.pitcherList = result.data.playerList;
          //console.log('완료', result.data.playerList)
        } else {
          this.isSelect = false;
          this.pitcherList = [];
        }
        
      });
      }
      
    },
    changeSelect() {  // 선수 변경
      this.$emit('playerChange', this.pitcher)
    }
  }
}
</script>
