<template>
  <table class="tb">
    <colgroup>
      <col width="100px">
      <col width="">
      <col width="115px">
      <col width="230px">
    </colgroup>
    <tr>
      <th>번호</th>
      <th>제목</th>
      <th>첨부</th>
      <th>등록일</th>
    </tr>
    <tr class="fixed" v-for="notice in topList" :key="notice.NOTICE_SE">
      <td>고정</td>
      <td class="title">
        <a href="#" @click.prevent="noticeDetail(notice.NOTICE_SE)">{{notice.TITLE_NM}}</a>
      </td>
      <td>
        <i v-if="notice.FILE_CK == 1" class="icon-file"></i>
      </td>
      <td>{{notice.REG_DT}}</td>
    </tr>
    <tr v-for="notice in noticeList" :key="notice.NOTICE_SE">
      <td>{{notice.ROW_NU}}</td>
      <td class="title">
        <a href="#" @click.prevent="noticeDetail(notice.NOTICE_SE)">{{notice.TITLE_NM}}</a>
      </td>
      <td>
        <i v-if="notice.FILE_CK == 1" class="icon-file"></i>
      </td>
      <td>{{notice.REG_DT}}</td>
    </tr>
    <tr v-if="noticeList.length == 0">
      <td colspan="4">
        검색 결과가 존재하지 않습니다.
      </td>
    </tr>
  </table>
</template>
<script>

import NoticeService from "@/utils/service/noticeService.js"

export default{ 
  name:'NoticeListView',
  components:{},
  props:{
    noticeList: {
      type: Array
    },
    topList: {
      type: Array
    }
  },
  data() {
    return{
      noticeService : new NoticeService(),
    };
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    noticeDetail(noticeSe) {
      this.$router.push(`/notice/${noticeSe}`);
    }
  }
}
</script>
