<template>
  <div class="modal"></div>
  <div class="popup">
    <div class="content">
      {{this.popupData.content}}
    </div>
    <div class="btn-wrap">
      <button v-show="this.popupData.buttonType == 2" @click.prevent="$emit('close-popup'); goEvent()">보러가기</button>
      <button class="cancel" @click="$emit('close-popup')">{{this.popupData.buttonType == 2 ? '닫기' : '확인'}}</button>
    </div>
  </div> 
</template>
<script>

export default{ 
  name:'PopupView',
  components:{},
  emits: ['go-event'],
  props:{
    popupInfo: {
      type: Object
    }
  },
  data() {
    return{
      sampleData: ''
    };
  },
  computed:{
    popupData(){
      return this.popupInfo;
    }
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    goEvent(){
      this.$emit('go-event', this.popupData)
    }
  }
}
</script>
