import axios from 'axios';

class ReportService {
  // 현재 이닝 / 초말 정보
  async $getNowInfo(gameId) {
    const result = axios.get('/Report/nowInfo', {
      params: {
        gameId: gameId
      }
    })
    return await result;
  }

  // 투수 정보 및 안내
  async $getPitcherInfo(gameId, innNo, tbSc){
    const result = axios.get('/Report/pitcherInfo', {
      params: {
        gameId: gameId
        , innNo: innNo
        , tbSc: tbSc
      }
    })
    return await result;
  }

  // 투수vs타자 맞대결
  async $getPitcherVsHitter(gameId, innNo, tbSc){
    const result = axios.get('/Report/vsHitter', {
      params: {
        //gameId: '20230725HHWO0'
        gameId: gameId
        , innNo: innNo
        , tbSc: tbSc
      }
    })
    return await result;
  }

  // 투수 로케이션
  async $getPitLocation(gameId, innNo, tbSc, pitId){
    const result = axios.get('/Report/location', {
      params: {
        gameId: gameId
        , innNo: innNo
        , tbSc: tbSc
        , pitId: pitId
      }
    })
    return await result;
  }
}

export default ReportService;