<template>
  <div class="board">
    <table class="tb type-view">
      <tr>
        <th>
          <span class="title">{{txtTitle}}</span>
        </th>
      </tr>
      <tr class="file-attach" v-for="file in this.fileList" :key="file.index">
        <td>
          <a href="#" @click.prevent="downFile(file.FILE_LK, file.FILE_NM)">
            <i class="icon-file"></i>
            {{file.FILE_NM}}
          </a>
        </td>
      </tr>
      <tr v-show="this.noticeVod.isVod">
        <td>
          <div style="width:1000px;height:600px;">
            <video class="video-js"
                    ref="videoPlayer" 
                    :src="noticeVod.vodLk" 
                    style="width:100%;height:100%;"
                    />
          </div>
        </td>
      </tr>
      <tr class="view">
        <td>
          <div class="ck-content" v-html="noticeCt" :editor="editor"></div>
        </td>
      </tr>
    </table>
    

    <div class="btn-wrap">
      <router-link to="/notice" class="btn">목록</router-link>
    </div>
  </div>
</template>
<script>

import axios from 'axios';
import videojs from 'video.js';
import 'video.js/dist/video-js.min.css'

import NoticeService from "@/utils/service/noticeService.js"
import '@ckeditor/ckeditor5-build-classic';

export default{ 
  name:'NoticeDetailView',
  components:{},
  data() {
    return{
      noticeService: new NoticeService(),
      
      txtTitle: '',        // 타이틀
      fileList: [],
      noticeVod:{
        isVod: false
        , vodLk: ''
      },
      noticeCt: '',

      player: null,
    };
  },
  setup() {},
  created() {this.getNotice(this.$route.params.num);},
  mounted() {
    this.getNotice(this.$route.params.num);

    const options = {
        autoplay: true,
        controls: true,
      };

    if(this.$refs.videoPlayer){
      this.player = videojs(this.$refs.videoPlayer, options);
      //this.player.play();
    }
  },
  unmounted() {},
  methods: {
    // 해당 공지 정보 불러오기
    getNotice(noticeSe){
      //console.log("조회 로직", noticeSe);

      this.noticeService.$getNotice(noticeSe).then(result => {
        if(result.data.code == 100){
          //console.log(result);
          this.txtTitle = result.data.TITLE_NM;                // 제목
          this.fileList = result.data.fileList;                // 첨부파일 목록
          if(result.data.VOD_LK.length > 0){
            //this.noticeVod = {isVod: true, vodLk: `http://test-rstp.sports2i.com/NCPB/${result.data.VOD_LK}`}
            this.noticeVod = {isVod: true, vodLk: `http://rstp.sports2i.com/NCPB/${result.data.VOD_LK}`}
          } else {
            this.noticeVod = {isVod: false}
          }
          this.noticeCt = result.data.NOTICE_CT;               // 내용
        }
      })
    },

    // 첨부파일 다운로드
    downFile(fileLk, fileNm){
      const fileUrl = `/File/download`; // API 엔드포인트 URL

      axios.get(fileUrl, {
        params:{
          file: fileLk
        },
        responseType: 'blob', // 파일 다운로드를 위해 responseType을 'blob'으로 설정
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: [response.data.type] }));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', fileNm); // 다운로드할 파일명 설정
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error('Failed to download file:', error);
      });
    }
  }
}
</script>
