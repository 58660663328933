<template>
  <h3>NOTICE</h3>
  <section v-if="$route.params.num">
    <NoticeDetailView></NoticeDetailView>
  </section>
  <section v-else>
    <div class="board">
      <SearchZoneView ref="searchzone" :select-box="this.selectBox" :data-section="this.dataSc" :search="txtSearch" :max-length="20" @search="setSearch"></SearchZoneView>
      <NoticeListView :top-list="this.topList" :notice-list="this.noticeList"></NoticeListView>
      <PagingView :page="this.page" @onPage="onPage"></PagingView>
    </div>
  </section>
</template>

<script>

import SearchZoneView from '@/components/Common/SearchZoneView.vue';
import NoticeListView from '@/components/Notice/NoticeListView.vue';
import NoticeDetailView from '@/components/Notice/NoticeDetailView.vue';
import PagingView from '@/components/Layout/PagingView.vue';

import NoticeService from "@/utils/service/noticeService.js"

export default{ 
  name:'NoticeView',
  components:{SearchZoneView, NoticeListView, NoticeDetailView, PagingView},
  data() {
    return{
      noticeService : new NoticeService(),

      topList: [],       // notice 상단 목록
      noticeList: [],    // notice 목록

      selectBox: [{value: 'TITLE_NM', text: '제목'}],
      dataSc: 'TITLE_NM',
      txtSearch: '',

      page: {
        total: 1,   // 총 데이터 수
        page: 1,    // 현재 페이지
        count: 10   // 한 페이지에 표시 수
      },

      sessionInterval: null,
    };
  },
  setup() {},
  created() {
    this.sessionInterval = setInterval(() => {
      this.$emit('session');
    }, 15000);
  },
  mounted() {
    this.getNoticeList(1);
  },
  unmounted() {
    clearInterval(this.sessionInterval);
  },
  methods: {
    // 데이터 초기화
    initData(){
      this.$refs.searchzone.selCondition = 'TITLE_NM';
      this.$refs.searchzone.txtSearch = '';

      const obj = {section: '', condition: 'TITLE_NM', text: ''}
      this.setSearch(obj);
    },

    // 공지 리스트
    async getNoticeList(pageNo) {
      let result = await this.noticeService.$getNoticeList(this.dataSc, this.txtSearch, this.page.count, pageNo);
      //console.log(result)
      this.topList = result.data.topList || [];
      this.noticeList = result.data.noticeList || [];
      this.page.total = result.data.totalCnt;
      this.page.page = pageNo;
    },

    // 페이지 변경 시
    onPage(page) {
      this.getNoticeList(page);
    },

    // 검색 click
    setSearch(data) {
      this.dataSc = data.condition;
      this.txtSearch = data.text;
      this.getNoticeList(1);
    },
  }
}
</script>
