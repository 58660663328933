<template>
  <h3 v-if="this.isPrint == false">벤치리포트</h3>
  <div v-if="this.isPrint == true" class="print-tit">
    <img src="@/assets/images/print/icon_logo.png" al="NC Dinos">
    <span>벤치리포트</span>
  </div>
  <section class="left-zone">
    <DateView ref="date" :is-print="this.isPrint" @gameInfo="changeGame" @selectbox="changeSelect" @initInterval="startInterval"></DateView>

    <div v-if="this.isPrint == false" class="btw-btn">
      <button @click="changeLive()">
        <img v-if="this.isLive == false" src="@/assets/images/contents/btn_play.png" alt="재생">
        <img v-else src="@/assets/images/contents/btn_stop.png" alt="일시정지">
      </button>
      <a class="btn-print" href="#" @click.prevent="clickPrint">
        <img src="@/assets/images/contents/icon_print.png" alt="print">
      </a>
      <a class="btn-pdf" href="#" @click.prevent="clickPDF()">
        <img src="@/assets/images/contents/icon_pdf.png" alt="pdf">
      </a>
    </div>

    <PitcherView ref="pitcher" @nowPitcher="nowPitcher"></PitcherView>
  </section>

  <section class="right-zone">
    <HitterGraphicView ref="vsHitter" :is-print="this.isPrint" @maxPage="maxPage"></HitterGraphicView>
    <PitLocationView ref="pitLocation" :game-id="gameId"></PitLocationView>
  </section>
</template>


<script>
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

import DateView from '@/components/Report/ReportLeftView/DateView.vue';
import PitcherView from '@/components/Report/ReportLeftView/PitcherView.vue';
import HitterGraphicView from '@/components/Report/ReportRightView/HitterGraphicView.vue';
import PitLocationView from '@/components/Report/ReportRightView/Location/PitLocationView.vue';

import Common from "@/utils/common.js"
import CommonService from "@/utils/service/commonService.js"
import ReportService from "@/utils/service/reportService.js"

export default{ 
  name:'ReportView',
  components:{DateView, PitcherView, HitterGraphicView, PitLocationView},
  emits: ['open-popup'],
  data() {
    return{
      common: new Common(),
      commonService : new CommonService(),
      reportService : new ReportService(),

      isLive: true,      // 재생 여부
      isPrint: false,    // 인쇄 시 숨김 처리 역할
      interval: null,

      gameInfo: {gameId: '', sectionId : ''},
      isGameLive: true,
      //gameId: '',
      //gameSectionId: '',
      nowInfo:{},     // 현재 이닝/초말
      nowPitcherInfo:{},  // 현재 투수 정보

      maxPageNo: 1,

      sessionInterval: null,
    };
  },
  setup() {},
  created() {
    this.sessionInterval = setInterval(() => {
      this.$emit('session');
    }, 15000);
  },
  mounted() {
    this.isGameIngCk();
    this.startInterval();
  },
  unmounted() {
    this.stopInterval();
    clearInterval(this.sessionInterval);
  },
  methods: {
    // 일시정지, 재생
    changeLive(){
      if(this.isLive){        // 재생 -> 일시정지
        this.stopInterval();

        // // 새로고침 1분간 정지
        // setTimeout(() => {
        //   this.startInterval();
        // }, 60000)
      } else {                // 일시정지 -> 재생
        if(this.isGameLive){
          this.startInterval();
        }
      }
    },

    // interval 재생
    startInterval(data){
      // 경기 없는 날, 종료된 경기는 새로고침 false
      if(!this.isGameLive){
        this.stopInterval();
      } else {
        if(!data){
          this.getData();
        }
        this.stopInterval();

        this.isLive = true;
        this.interval = setInterval(this.getData, 15000);
      }
    },

    // interval 정지
    stopInterval(){
      this.isLive = false; 
      clearInterval(this.interval);
    },

    // 데이터 초기화
    initData(){
      this.$refs.date.initData();
    },

    // PDF 다운 버튼 클릭
    clickPDF(){
      this.isPrint = true;

      setTimeout(() => {
        this.makePDF();
      }, 1000)
    },

   // pdf 생성
    makePDF() {
      const element = document.getElementById('contents');
      const options = {
        //scrollX: 0,
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        scale: 2,
      };

      if (element) {
        const printWindow = window.open(`/report/print?inn=${encodeURIComponent(this.nowInfo.inn)}&tb=${encodeURIComponent(this.nowInfo.tb)}`, '', 'width=850,height=600');
        
        setTimeout(() => {
          const body = printWindow.document.getElementById('container');
          body.style.width = "1400px";
          if(body){
            body.appendChild(element.cloneNode(true));
          }

          const queryString = printWindow.location.search;
          const params = new URLSearchParams(queryString);

          setTimeout(() => {
            printWindow.document.querySelector("#selInn").value = params.get('inn');
            printWindow.document.querySelector("#selTb").value = params.get('tb');
              
            html2canvas(body, options).then(canvas => {
              const imgData = canvas.toDataURL('image/png');
              const pdfWidth = body.offsetWidth;
              const pdfHeight = body.offsetHeight;

              body.appendChild(canvas);
              //console.log("pdfWidth", pdfWidth, "   pdfHeight", pdfHeight, "    body", body)

              // PDF 페이지 크기 설정
              const pdf = new jsPDF('landscape', 'px', [pdfWidth, pdfHeight]);  // landscape: 가로
              pdf.addImage(imgData, 'png', 0, 0, pdfWidth, pdfHeight);
              pdf.save(`${this.gameInfo.gameId.substring(0,8)}_${params.get('inn')}회_벤치리포트.pdf`);
              
              printWindow.close();
            });
          }, 2000)

          this.isPrint = false;
          
        }, 1000)
      }
    },

    // 인쇄 버튼 클릭
    clickPrint() {
      this.isPrint = true;
      
      setTimeout(() => {
        this.print(this.isPrint)
      }, 1000)
    },

    // 인쇄 동작
    print(){
      const element = document.getElementById('contents');

      if (element) {
        //console.log(element)
        const printWindow = window.open(`/report/print?inn=${encodeURIComponent(this.nowInfo.inn)}&tb=${encodeURIComponent(this.nowInfo.tb)}`, '', 'width=800,height=600');

        setTimeout(() => {
          const body = printWindow.document.getElementById('container');
          printWindow.document.body.style.backgroundColor = "#fff";       // 인쇄용 팝업 <body />의 background-color css
          if(body){
            body.appendChild(element.cloneNode(true));
          }

          const queryString = printWindow.location.search;
          const params = new URLSearchParams(queryString);

          setTimeout(()=> {
            printWindow.document.querySelector("#selInn").value = params.get('inn');
            printWindow.document.querySelector("#selTb").value = params.get('tb');

            printWindow.document.close();
            printWindow.print();
            printWindow.onafterprint = function () {
              printWindow.close();
            };
          }, 2000)

          this.isPrint = false;
          
        }, 1000)
      }
    },

    // 데이터 가져오기
    getData(){
      this.$nextTick(() => {
        if(this.$refs.date){
          this.$refs.date.getGameInfo(this.$refs.date.gameInfo.headerNo);
        }
      });
    },

    changeGame(data){
      //console.log('emit gameInfo', data)
      this.gameInfo = {gameId: data.gameId, sectionId: data.sectionId};

      this.isGameIngCk();
      
      // 경기 없는 날, 종료된 경기는 새로고침 false
      if(!this.isGameLive){
        this.stopInterval();
      } else {
        this.startInterval(true);
      }
    },

    changeSelect(data){
      //console.log('emit changeSelect', data, "gameId", this.gameInfo.gameId);
      this.nowInfo = data;
      this.$refs.pitcher.getPitcherInfo(this.gameInfo.gameId, data);
      this.$refs.vsHitter.getPitcherVsHitter(this.gameInfo.gameId, data, this.maxPageNo, true);
    },

    // 현재 투수
    nowPitcher(data){
      //console.log("현재 투수", data, this.gameInfo.gameId, this.nowInfo)
      this.nowInfo.pId = data.P_ID;
      this.$refs.pitLocation.getLocation(this.gameInfo.gameId, this.nowInfo);
    },

    // 맞대결 페이지 설정 및 호출
    maxPage(max){
      if(this.maxPageNo != max){
        this.maxPageNo = max;
        if(!this.isGameLive){
          this.$refs.vsHitter.getPitcherVsHitter(this.gameInfo.gameId, this.nowInfo, max, false);
        } else {
          this.$refs.vsHitter.getPitcherVsHitter(this.gameInfo.gameId, this.nowInfo, max, true);
        }
      }
    },

    // 경기 진행중 체크
    isGameIngCk(){
      if(this.gameInfo.sectionId != '2'){   // 경기 진행 중 아닐 때
        this.isLive = false;
        this.isGameLive = false;
      } else {                              // 경기 진행 중
        this.isLive = true;
        this.isGameLive = true;
      }
    },
  }
}
</script>
