<template>
  <h3>게임노트</h3>
  <section>
    <div class="day-wrap">
      <DoubleHeaderView v-show="this.isDoubleHeader" :double-header="this.doubleHeader" @doubleHeader="changeHeader"></DoubleHeaderView>
      <DayBoxView @gameDate="changeDate"></DayBoxView>
      <!-- <GameNoteDetail :game-date="this.gameDate" :game-id="this.gameId"></GameNoteDetail> -->
      <GameNoteDetail ref="gamenoteref"></GameNoteDetail>
      <GameNoteDownloadBtnView ref="homegamefileref"></GameNoteDownloadBtnView>
    </div>
  </section>
</template>

<script>

import DoubleHeaderView from '@/components/Common/DoubleHeaderView.vue';
import DayBoxView from '@/components/Common/DayBoxView.vue';
import GameNoteDetail from '@/components/GameNote/GameNoteDetailView.vue';
import GameNoteDownloadBtnView from '@/components/GameNote/GameNoteDownloadBtnView.vue';

import Common from "@/utils/common.js"
import CommonService from "@/utils/service/commonService.js"

export default{ 
  name:'GameNoteView',
  components:{DoubleHeaderView, DayBoxView, GameNoteDetail, GameNoteDownloadBtnView},
  data() {
    return{
      common: new Common(),
      commonService : new CommonService(),
      gameDate: '', 
      gameId: '',
      isDoubleHeader: false,  // true: 더블헤더 O, false: 더블헤더 X
      doubleHeader: 0         // 더블헤더 값
    };
  },
  setup() {},
  created() {
    this.gameDate = this.common.yyyyMMdd(new Date(),'');
    //this.getGameInfo(0);
  },
  mounted() {
    this.getGameInfo(0);
  },
  unmounted() {},
  methods: {
    changeDate(data) {
      this.gameDate = data;
      this.getGameInfo(0);
    },

    changeHeader(data) {
      //console.log('더블헤더', data)
      this.doubleHeader = data;
      this.getGameInfo(data);
    },

    // 경기 정보 가져오기
    getGameInfo(headerNo) {
      this.commonService.$getGameInfo(this.gameDate).then(result =>{
        if(result.data.GAME_CN > 0) {
          if(result.data.DOUBLE_CK === '1') {         // 더블헤더 확인
            this.isDoubleHeader = true;
            this.doubleHeader = headerNo === 0 ? 1 : headerNo;
            let gameList = result.data.gameList.filter(item => item.HEADER_NO == this.doubleHeader);  // 더블헤더 gameid
            this.gameId =  gameList[0].G_ID;  // 더블헤더 gameid
          } else {
            this.isDoubleHeader = false;
            this.doubleHeader = result.data.DOUBLE_CK;  // 더블헤더
            this.gameId = result.data.gameList[0].G_ID; // gameid
          }

          this.getDetailCall(); // gamenoedetailview 호출
          this.getHomeGameFile(this.gameId, this.gameDate); // [주요행사/큐시트], [KBO기록/순위/엔트리] 파일 호출
        } else {
          this.getDetailCall();
          this.getHomeGameFile('', this.gameDate);
        }
      })
    },
    
    getDetailCall() { // gamenoedetailview 호출
      this.$refs.gamenoteref.getGameNoteDetailData(this.gameDate, this.gameId);
    },

    getHomeGameFile(gameId, gameDate) {
      this.$refs.homegamefileref.getHomeGameFileData(gameId, gameDate);
    }
  }
}
</script>


