<template>
  <h4>만나이 및 일자 계산</h4>
  <table class="tb calc age">
    <colgroup>
      <col width="135px">
      <col width="195px">
      <col width="">
      <col width="270px">
    </colgroup>
    <tr>
      <th>구분</th>
      <th colspan="2">계산</th>
      <th>결과</th>
    </tr>
    <tr>
      <th class="sub-tit">만나이</th>
      <td class="noti">* 출생일을 직접 입력해주세요.</td>
      <td>
        <span>출생일</span>
        <input type="number" class="date" v-model="birthDate" @keyup.enter="calAge()">
        
        <span>기준일</span>
        <input type="number" class="date" v-model="ageStandard">
        <a href="#" class="btn today" @click.prevent="setToday('age')">오늘</a>
        <a href="#" class="btn gray" @click.prevent="calAge()">계산</a>
      </td>
      <td>
        <input type="text" :value='this.ageResult.length == 0 ? `` : `${this.ageResult}`' disabled>
      </td>
    </tr>
    <tr>
      <th class="sub-tit">일자</th>
      <td class="noti">
        * 오늘은 기준일부터 1일째<br/> 
        &nbsp;&nbsp;되는 날입니다.
      </td>
      <td>
        <span>계산일</span>
        <input type="number" class="date" v-model="beforeDate" @keyup.enter="calDateDiff()">

        <span>기준일</span>
        <input type="number" class="date" v-model="dateStandard">
        <a href="#" class="btn today" @click.prevent="setToday('date')">오늘</a>
        <a href="#" class="btn gray" @click.prevent="calDateDiff()">계산</a>
      </td>
      <td>
        <input type="text" :value='this.dateResult.length == 0 ? `` : `${this.dateResult - 1}일 만, ${this.dateResult}일 연속(일째)`' disabled>
      </td>
    </tr>
  </table>
</template>
<script>

import Common from "@/utils/common.js"

export default{ 
  name:'CalAgeDateView',
  components:{},
  emits: ['open-popup'],
  data() {
    return{
      common: new Common(),
      birthDate: '',       // 출생일
      ageStandard: '',     // 만나이 기준일
      ageResult: '',       // 만나이 결과
      
      beforeDate: '',      // 계산일
      dateStandard: '',    // 일자 기준일
      dateResult: '',      // 일자 계산 결과
    };
  },
  setup() {},
  created() {},
  mounted() {
    this.setToday('age');
    this.setToday('date');
  },
  unmounted() {},
  methods: {
    // 오늘 날짜 setting
    setToday(section){
      let today = new Date();
      let year = today.getFullYear();
      let month = ("0" + (1 + today.getMonth())).slice(-2);
      let day = ("0" + today.getDate()).slice(-2);
      
      if(section == 'age'){
        this.ageStandard = `${year}${month}${day}`;
      } else {
        this.dateStandard = `${year}${month}${day}`;
      }
    },

    // 만나이 계산
    calAge(){
      if(this.birthDate.length == 0 || this.ageStandard.length == 0){
        this.$emit('open-popup', {content: '날짜를 입력해주세요.', buttonType: 1});
      } else {
        const birthday = new Date(this.common.yyyyMMddDash(this.birthDate));
        const standard = new Date(this.common.yyyyMMddDash(this.ageStandard));

        if(birthday == 'Invalid Date' || standard == 'Invalid Date'){
          this.$emit('open-popup', {content: '날짜 입력 형식 확인해주세요.', buttonType: 1});
        } else {
          // let age = 0;

          // age = standard.getFullYear() - birthday.getFullYear();
          // birthday.setFullYear(standard.getFullYear());

          // if (standard.getTime() < birthday.getTime()) {
          //   age--;
          // }

          // this.ageResult = age;

          const ageInMilliseconds = standard - birthday;
          // 밀리초를 년, 월, 일로 변환
          const millisecondsInYear = 1000 * 60 * 60 * 24 * 365.25; // 평균 연도의 밀리초 수
          const millisecondsInMonth = millisecondsInYear / 12; // 평균 월의 밀리초 수

          const years = Math.floor(ageInMilliseconds / millisecondsInYear); // 연 수 계산
          const months = Math.floor((ageInMilliseconds % millisecondsInYear) / millisecondsInMonth); // 월 수 계산
          const days = Math.floor((ageInMilliseconds % millisecondsInMonth) / (1000 * 60 * 60 * 24)) + 1; // 일 수 계산

          this.ageResult = `${years}세 ${months}개월 ${days}일`
        }
      }
    },

    // 일자 계산
    calDateDiff(){
      if(this.beforeDate.length == 0 || this.dateStandard.length == 0){
        this.$emit('open-popup', {content: '날짜를 입력해주세요.', buttonType: 1});
      } else {
        const before = new Date(this.common.yyyyMMddDash(this.beforeDate));
        const standard = new Date(this.common.yyyyMMddDash(this.dateStandard));

        if(before == 'Invalid Date' || standard == 'Invalid Date'){
          this.$emit('open-popup', {content: '날짜 입력 형식 확인해주세요.', buttonType: 1});
        } else {
          const diffDate = before.getTime() - standard.getTime();
          this.dateResult = Math.abs(diffDate / (1000 * 60 * 60 * 24)) + 1;   // 오늘이 1일째라서 +1 해줌
        }
      }
    }
  }
}
</script>
