<template>
  <div v-show="pageType != 'round'" class="pagenation">
    <a class="prev" href="#" @click.prevent="prevPage()">
      <span>prev</span>
    </a>
    <span class="num">
      <ul>
        <li v-for="item in countInPages" :key="item" :class="{'on' : (startPage - 1) + item == page.page, 'one' : countInPages == 1}">
          <a href="#" @click="selectPage((startPage-1)+item)">{{(startPage-1) + item}}</a>
        </li>
      </ul>
    </span>
    <a class="next" href="#" @click.prevent="nextPage()">
      <span>next</span>
    </a>
  </div>

  <div v-show="pageType == 'round'" class="page">
    <span class="round">
      <a v-for="item in countInPages" :key="item" :class="{'on' : (startPage - 1) + item == page.page, 'one' : countInPages == 1}" href="#" @click="selectPage((startPage-1)+item)"></a>
    </span>
  </div>
</template>
<script>

export default{ 
  name:'PagingView',
  components:{},
  props:{
    page: {
      type: Object
    },
    defaultPages: {
      type: Number,
      default: 10
    },
    pageType:{
      type:String,
    }
  },
  data() {
    return{
      sampleData: ''
    };
  },
    computed:{
    startPage: function () {
      let value = (Math.floor((this.page.page-1) / this.defaultPages) * this.defaultPages) + 1
      return value;
    },
    totalPage: function () {
      var count = this.page.count ? this.page.count : 10
      return Math.floor(this.page.total / count) + ((this.page.total % count) == 0 ? 0 : 1)
    },
    countInPages: function () {
      let currPages = (this.totalPage == 0 ? 1 : this.totalPage) - (this.startPage-1);
      //console.log('curr', currPages, 'default', this.defaultPages)
      if (currPages < this.defaultPages) {
        return currPages;
      } else {
        return this.defaultPages;
      }
    }
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    selectPage: function (page) {
      this.$emit("onPage", page);
    },
    
    prevPage: function () {
      if (this.page.page == 1) {
        return;
      }
      this.selectPage(this.page.page - 1)
    },

    nextPage: function () {
      if (this.page.page >= this.totalPage) {
        return;
      }
      this.selectPage(this.page.page + 1)
    }
  }
}
</script>
