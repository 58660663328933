<template>
<div class="tb-scroll">
  
</div>
  <div class="board">
    <div class="box" v-for="vs in vsList" :key="vs" :class="{on: this.nowInfo.maxPa == vs.ALL_PA_ORDER_NO && isPrint == false}" :data-pa="vs.ALL_PA_ORDER_NO">
      <table class="tb-xs">
        <colgroup>
          <col width="60px">
        </colgroup>
        <tr>
          <td colspan="2">
            <span class="point-txt">투</span>
            {{vs.PIT_P_BACK_NO}} {{vs.PIT_P_NM}} {{vs.PIT_PIT_DIREC_CD == '3' ? '右' : '左'}}
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <span class="point-txt">포</span>
            {{vs.CAT_P_BACK_NO}} {{vs.CAT_P_NM}} {{vs.CAT_HIT_DIREC_CD == '9' ? '右' : '左'}}
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <span class="point-txt">타</span>
            {{vs.BAT_P_BACK_NO}} {{vs.BAT_P_NM}} {{vs.BAT_HIT_DIREC_CD == '9' ? '右' : '左'}}
          </td>
        </tr>
        <tr>
          <td>{{vs.BALL}}B {{vs.STRIKE}}S</td>
          <td>{{vs.OUT == '0' ? '무' : vs.OUT}}사 {{vs.BASE}}</td>
        </tr>
        <tr>
          <td>{{vs.AWAY_SCORE_CN}}-{{vs.HOME_SCORE_CN}}</td>
          <td>{{vs.BAT_RESULT}}</td>
        </tr>
      </table>

      <div class="location">
        <div class="s2i_point s2i_size2">
          <span class="s2i_ball" v-for="ball in vs.ballList" :key="ball" :style="`left:${ball.x}px; top:${ball.y}px;`" :g-id="ball.gId" :seq-no="ball.seqNo">
            <a :class="`ball_type${ball.color}_${ball.type}`">{{ball.no}}</a>
            <span v-if="ball.ballC" class='_c'>C</span>
            <span v-if="ball.ballP" class='_p'>P</span>
            <span v-if="ball.ballE" class='_e'>E</span>
            <span v-if="ball.ballI" class='_i'>I</span>
            <span v-if="ball.ballU" class='_u'>U</span>
            <span v-if="ball.ballJ" class='_j'>J</span>
          </span>
        </div>
      </div>

      <div class="tb-scroll">
        <table class="tb-xs">
          <colgroup>
            <col width="23px;">
          </colgroup>
          <tr v-for="(index) in 5" :key="index" class="s2i_size4">
            <template v-for="(idx) in (vs.colCnt)" :key="idx">
              <td>{{vs.ballList[calculationIndex(index, idx)] ? vs.ballList[calculationIndex(index, idx)].speed : ''}}</td>
              <td>
                <div v-if="vs.ballList[calculationIndex(index, idx)]" style="margin: 0 0 0 5px" class="s2i_ball">
                  <a href="#"
                    :class="`${vs.ballList[calculationIndex(index, idx)]
                    ? `ball_type${vs.ballList[calculationIndex(index, idx)].color}_${vs.ballList[calculationIndex(index, idx)].type}` : ''}`">
                  </a>
                </div>
              </td>
              <td class="point-txt">{{vs.ballList[calculationIndex(index, idx)] ? vs.ballList[calculationIndex(index, idx)].rank : ''}}</td>
            </template>
          </tr>
          
        </table>
      </div>
    </div>

    <div class="box" v-for="vs in 5 - vsLength" :key="vs">
      <table class="tb-xs">
        <colgroup>
          <col width="60px">
        </colgroup>
        <tr>
          <td colspan="2">
            <span class="point-txt" style='margin:0px'>투</span>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <span class="point-txt" style='margin:0px'>포</span>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <span class="point-txt" style='margin:0px'>타</span>
          </td>
        </tr>
        <tr>
          <td td colspan="2">-</td>
        </tr>
        <tr>
          <td td colspan="2">-</td>
        </tr>
      </table>
      <div class="location">
        <div class="s2i_point"></div>
      </div>
      <div class="tb-scroll">
        <table class="tb-xs">
          <colgroup>
            <col width="23px;">
          </colgroup>
          <tr>
            <td td colspan="9">-</td>
          </tr>
          <tr>
            <td td colspan="9">-</td>
          </tr>
          <tr>
            <td td colspan="9">-</td>
          </tr>
          <tr>
            <td td colspan="9">-</td>
          </tr>
          <tr>
            <td td colspan="9">-</td>
          </tr>
        </table>
      </div>
    </div>
    <PagingView v-if="page.total &gt; 5 && isPrint == false" :page="this.page" :page-type="`round`" @onPage="onPage"></PagingView>
  </div>
</template>

<script>
import PagingView from '@/components/Layout/PagingView.vue';

import ReportService from "@/utils/service/reportService.js"

export default{ 
  name:'HitterGraphicView',
  components:{PagingView},
  emits:['maxPage'],
  props:{
    isPrint: {
      type: Boolean
    },
  },
  data() {
    return{
      reportService : new ReportService(),

      vsList: [],     // 맞대결 타석 목록
      vsLength: 0,    // 맞대결 타석 개수

      gameId: '',
      nowInfo: {},

      page: {
        total: 1,   // 총 데이터 수
        page: 1,    // 현재 페이지
        count: 5   // 한 페이지에 표시 수
      },
    };
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    // 맞대결 데이터 조회
    getPitcherVsHitter(gameId, nowInfo, pageNo, isChange){
      this.gameId = gameId;
      this.nowInfo = nowInfo;

      let startIndex = (pageNo * 5) - (5 - 1);
      let endIndex = startIndex + (5 - 1);

      if(gameId.length > 0){   // 경기 있는 경우
        this.reportService.$getPitcherVsHitter(gameId, nowInfo.inn, nowInfo.tb).then(result => {
          //console.log("getPitcherVsHitter", result)
          if(result.data.code == '100'){
            //this.vsList = result.data.vsList || [];
            //this.vsLength = result.data.vsList.length > 5 ? 5 : result.data.vsList.length;
            this.vsList = result.data.vsList.slice(startIndex - 1, endIndex);
            this.vsLength = this.vsList.length > 5 ? 5 : this.vsList.length;
            this.page.total = result.data.vsList.length;
            this.page.page = pageNo;

            // isChange true인 경우 maxPage값으로 자동 넘어감
            if(isChange){
              this.$emit("maxPage", Math.ceil(this.page.total / 5) || 1);
            }
          }
        })
      } else{       // 경기 없는 경우
        this.vsList = [];
        this.vsLength = 0;
        this.page.total = 0;
        this.$emit("maxPage", 1);
      }
    },

    // 공 index 계산
    calculationIndex(x, y){
      return (y - 1) * 5 + (x - 1);
    },

    // 페이지 변경 시
    onPage(page) {
      this.getPitcherVsHitter(this.gameId, this.nowInfo, page, false);
    },
  }
}
</script>
