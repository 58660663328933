<template>
  <div class="tb-scroll">
    <table class="tb-mini">
      <colgroup>
        <col width="68px">
        <col width="">
        <col width="66px">
      </colgroup>
      <tr>
        <th>팀</th>
        <th>내용</th>
        <th>스코어</th>
      </tr>
      <template v-if="tableData.length === 0">
        <tr>
          <td colspan="3" style="text-align: center;">데이터가 존재하지 않습니다.</td>
        </tr>
      </template>
      <template v-else>
        <tr v-for="item in tableData" :key="item.SEQ_NO" :class="{ 'now': item.TEXTSTYLE_SC == 0 }">
          <td :class="`${item.TEXTSTYLE_SC == 0 || item.TEXTSTYLE_SC == 99 ? '' : item.HIT_TEAM == 'NC' ? 'home' : 'away'}`">
            {{ item.TEXTSTYLE_SC == 99 ? "" : selectInning == 0 ? `${item.INN_NO}회${item.TB_SC} ${item.HIT_TEAM}` : item.HIT_TEAM }} 
          </td>
          <td :class="`${item.TEXTSTYLE_SC == 99 ? 'result' : ''}`">
            {{ item.LIVETEXT_IF }}
          </td>
          <td>{{ item.AWAY_HOME_RUN_CN }}</td>
        </tr>
      </template>
    </table>
  </div>
</template>
<script>

export default{ 
  name:'KeyPointTable',
  components:{},
  props:{
    tableData:{
      Type: Object
    },
    selectInning: {
      type: String
    }
  },
  data() {
    return{
      
    };
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {}
}
</script>
