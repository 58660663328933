<template>
	<DetailRecordTable :team-name="homeTeamName" :pitcher-data="this.homePitcher" :hitter-data="this.homeHitter"></DetailRecordTable>
	<DetailRecordTable :team-name="awayTeamName" :pitcher-data="this.awayPitcher" :hitter-data="this.awayHitter"></DetailRecordTable>
</template>
<script>

import DetailRecordTable from '@/components/Broadcast/Detail/DetailRecordTable.vue';
import BroadcastService from "@/utils/service/broadcastService.js"

export default{ 
  name:'DetailRecord',
  components:{DetailRecordTable},
  props: {
    gameInfo: {
      type: Object
    }
  },
  data() {
    return{
      broadcastService: new BroadcastService(),
      homeTeamName: '', // 홈팀
      homeHitter: [],   // 홈팀 타자
      homePitcher: [],  // 홈팀 투수
      awayTeamName: '', // 원정팀
      awayHitter: [],   // 원정팀 타자
      awayPitcher: []   // 원정팀 투수
    };
  },
  watch: {
    'gameInfo.gameId': {
      handler(gameId) {
        this.getRecordData(gameId)
      },
    }
  },
  // watch: {
  //   gameInfo(newData) {
  //     console.log('///', newData);
  //     //this.getData(); // 게임노트 호출
  //   },
  // },
  // watch: {
  //   gameInfo: {
  //     deep: true,
  //     handler(newData) {
  //       console.log(`data change ${newData}`)
  //     },
  //   }
  // }, 
  setup() {},
  created() {},
  mounted() {
    //console.log('mount', this.gameInfo.gameId)
    this.getRecordData(this.gameInfo.gameId)
  },
  unmounted() {},
  methods: {
    getRecordData(gameId) {

      if(gameId === '') {
        //console.log('없음');
      } else {
        this.broadcastService.$getRecord(gameId).then(result => {
        if(result.status === 200) {
          this.homeTeamName = result.data.homeTeamName; // 홈팀
          this.homeHitter = result.data.homeHitter;     // 홈팀 타자
          this.homePitcher = result.data.homePitcher;   // 홈팀 투수

          this.awayTeamName = result.data.awayTeamName; // 원정팀
          this.awayHitter = result.data.awayHitter;     // 원정팀 타자
          this.awayPitcher = result.data.awayPitcher;   // 원정팀 투수

          //console.log('homePitcher', this.homePitcher.length)
          //this.homeArray = result.homePitcher
          //console.log('성공', result.data);
        } else {
          //console.log('실패');
          this.homeTeamName =  '', // 홈팀
          this.homeHitter =  [],   // 홈팀 타자
          this.homePitcher =  [],  // 홈팀 투수
          this.awayTeamName =  '', // 원정팀
          this.awayHitter =  [],   // 원정팀 타자
          this.awayPitcher =  []   // 원정팀 투수
        }
      });
      }
      
    }
    // getData() {
    //   console.log('asdfasdfasfd')
    // }
  }
}
</script>
