<template>
  <div class="board score">
    <ul>
      <li class="top">
        <table>
          <caption>점수판</caption>
          <tr>
            <th scope="col" class="team">팀명</th>
            <th scope="col" v-for="index in 15" :key="index" >{{index}}</th>
            <th scope="col" class="point r">R</th>
            <th scope="col" class="point">H</th>
            <th scope="col" class="point">E</th>
            <th scope="col" class="point">B</th>
          </tr>
        </table>
      </li>
      <li class="bottom">
        <table>
          <caption>점수판</caption>
          <tr>
            <td class="team">{{gameInfoData.awayNm}}</td>
            <td v-for="(away) in scoreDataData.arrAway" :key="away.INN_NO">{{away.SCORE_CN}}</td>
            <td v-for="index in (15 - scoreDataData.arrAway.length)" :key="index">-</td>
            <td class="point r">{{scoreDataData.rheb.length &gt; 0 ? scoreDataData.rheb[0].RUN_CN : '-'}}</td>
            <td class="point">{{scoreDataData.rheb.length &gt; 0 ? scoreDataData.rheb[0].HIT_CN : '-'}}</td>
            <td class="point">{{scoreDataData.rheb.length &gt; 0 ? scoreDataData.rheb[0].ERR_CN : '-'}}</td>
            <td class="point">{{scoreDataData.rheb.length &gt; 0 ? scoreDataData.rheb[0].BBHP_CN : '-'}}</td>
          </tr>
          <tr>
            <td class="team">{{gameInfoData.homeNm}}</td>
            <td v-for="(home) in scoreDataData.arrHome" :key="home.INN_NO">{{home.SCORE_CN}}</td>
            <td v-for="index in (15 - scoreDataData.arrHome.length)" :key="index">-</td>
            <td class="point r">{{scoreDataData.rheb.length &gt; 0 ? scoreDataData.rheb[1].RUN_CN : '-'}}</td>
            <td class="point">{{scoreDataData.rheb.length &gt; 0 ? scoreDataData.rheb[1].HIT_CN : '-'}}</td>
            <td class="point">{{scoreDataData.rheb.length &gt; 0 ? scoreDataData.rheb[1].ERR_CN : '-'}}</td>
            <td class="point">{{scoreDataData.rheb.length &gt; 0 ? scoreDataData.rheb[1].BBHP_CN : '-'}}</td>
          </tr>
        </table>
      </li>
    </ul>
  </div>
</template>
<script>

import Common from "@/utils/common.js"
import BroadcastService from "@/utils/service/broadcastService.js"


export default{ 
  name:'ScoreBoardView',
  components:{},
  props:{
    gameInfo:{
      Type: Object
    },
    scoreData:{
      Type: Object
    },
  },
  data() {
    return{
      common: new Common()
      , broadcastService : new BroadcastService()

    };
  },
  computed:{
    gameInfoData(){
      return this.gameInfo || [];
    },
    scoreDataData(){
      return this.scoreData || {arrAway: [], arrHome: [], rheb:[]};
    },
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    
  }
}
</script>
