import axios from 'axios';

class MemberService {
  // 로그인
  async $login(id, password, isSave) {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("password", password);
    formData.append("isSave", isSave);
    const result = axios.post("/Member/login", formData);

    return await result;
  }

  // 로그아웃
  async $logout() {
    axios.get("/Member/logout");
  }

  // 세션 값 조회
  async $session(){
    const result = axios.get("/Member/session");
    return await result;
  }

  // 허용 IP 체크
  async $ipCheck() {
    const result = axios.get("/Member/ipcheck");
    return await result;
  }
}

export default MemberService;