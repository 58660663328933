<template>
  <h3>계산</h3>
  <section>
    <div class="board">
      <CalRecordView @open-popup="openPopup"></CalRecordView>
      <CalAgeDateView @open-popup="openPopup"></CalAgeDateView>
    </div>
  </section>
</template>

<script>

import CalRecordView from '@/components/Calculation/CalRecordView.vue';     // 기록계산
import CalAgeDateView from '@/components/Calculation/CalAgeDateView.vue';   // 만나이 및 일자 계산

export default{ 
  name:'CalculationView',
  components:{CalRecordView, CalAgeDateView},
  emits: ['open-popup'],
  data() {
    return{
      sessionInterval: null,
    };
  },
  setup() {},
  created() {
    this.sessionInterval = setInterval(() => {
      this.$emit('session');
    }, 15000);
  },
  mounted() {},
  unmounted() {
    clearInterval(this.sessionInterval);
  },
  methods: {
    openPopup(data){
      this.$emit('open-popup', data)
    },
  }
}
</script>
