import axios from 'axios';

class GameNoteService {
  // nc gamenote
  async $getGameNote(gameDate, gameId) {
    //let gameDate = '20230708';
    //const result = axios.get(`/gamenote/gameNote/${gameDate}/${gameId}`);

    const result = axios.get('/GameNote/note', {
      params: {
        gameDate: gameDate
        , gameId: gameId
      }
    });

    return await result;
  }

  // 홈경기 자료 불러오기
  async $getHomeGameFile(gameId) {
    const result = axios.get('/GameNote/homegamefile', {
      params: {
        gameId: gameId
      }
    });

    return await result;
  }

  // 카테고리 목록 조회하기
  // async $getGamNote() {
  //   let gameDate = '20230708';
  //   //const result = axios.get(`/GameNote/getGameNote/${gameDate}`);
  //   const result = axios.get('/GameNote/getGameNote', {
  //     params: {
  //       gameDate: gameDate
  //     }
  //   })
  //   return await result;
  // }

  // async $getGamNote2() {
  //   // let gameDate = '20230708';
  //   // const result = axios.get(`/Notice/getNotice/${gameDate}`);
  //   let gameDate = '20230708';
  //   //const result = axios.get(`/GameNote/getGameNote/${gameDate}`);
  //   const result = axios.get('/gamenote/gamenote2', {
  //     params: {
  //       gameDate: gameDate
  //     }
  //   })
  //   return await result;
  // }


  // async $getGameNote6() {
  //   let gameDate = '20230708';
  //   const result = axios.get('/GameNote/getGameNote', {
  //     params: {
  //       gameDate: gameDate
  //     }
  //   })
  //   return await result;
  // }


  // async $getGameNote() {
  //   let gameDate = '20230708';
  //   const result = axios.get(`/GameNote/getgamenote/${gameDate}`);
  //   return await result;

  // }

  // async $getGameNote4() {
  //   let gameDate = '20230708';
  //   const result = axios.get(`/GameNote/abcdefg/${gameDate}`);
  //   return await result;

  // }


  // async $getGamNote2() {
  //   const result = axios.get('/GameNote/gamenote2');
  //   return await result;
  // }
}

export default GameNoteService;