<template>
  <ul class="toggle">
    <li :class="{on : doubleHeader == 1}"><a href="#" @click.prevent="changeDH" data-header="1">DH1</a></li>
    <li :class="{on : doubleHeader == 2}"><a href="#" @click.prevent="changeDH" data-header="2">DH2</a></li>
  </ul>
</template>
<script>

export default{ 
  name:'DoubleHeaderView',
  components:{},
  emits: ['doubleHeader'],
  props:{
    doubleHeader: {
      type: Number
    },
  },
  data() {
    return{
    };
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    // 더블헤더 변경
    changeDH(e){
      // var sections = e.target.parentNode.parentNode.children;
      // for(var i = 0; i < sections.length; i++){
      //     sections[i].classList.remove('on');
      // }
      // e.target.parentNode.classList.add("on");

      this.$emit('doubleHeader', e.target.getAttribute('data-header'));
    }
  }
}
</script>
