<template>
<div class="box">
    <p class="tb-tit">{{teamName}}</p>
    <div class="tb-box">
      <div class="tb-scroll">
        <table class="tb-mini">
          <colgroup>
            <col width="16px">
            <col width="36px">
            <col width="60px">
          </colgroup>
          <tr>
            <th colspan="3">타자</th>

            <th>타수</th>
            <th>득점</th>
            <th>안타</th>
            <th>홈런</th>
            <th>타점</th>
            
            <th>도루</th>
            <th>희타</th>
            <th>볼넷</th>
            <th>삼진</th>
            <th>병살</th>
            <th>실책</th>
          </tr>
          <template v-if="hitterData.length === 0">
            <tr>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
          </template>
          <template v-else-if="hitterData.length &gt; 0">
            <tr v-for="hitter in hitterData" :key="hitter.P_ID">
              <td class="sub-tit" :class="`${hitter.T_ID == 'NC' ? 'home' : 'away'}`">{{ hitter.BAT_ORDER_NO }}</td>
              <td class="sub-tit" :class="`${hitter.T_ID == 'NC' ? 'home' : 'away'}`" :style="hitter.POS_SC.indexOf('→') &gt; 0 ? {fontSize: '11px'} : ''">{{ hitter.POS_SC }}</td>
              <td class="sub-tit" :class="`${hitter.T_ID == 'NC' ? 'home' : 'away'}`">{{ hitter.P_NM }}</td>

              <td class="">{{ hitter.AB_CN }}</td>
              <td class="">{{ hitter.RUN_CN }}</td>
              <td class="">{{ hitter.HIT_CN }}</td>
              <td class="">{{ hitter.HR_CN }}</td>
              <td class="">{{ hitter.RBI_CN }}</td>

              <td class="">{{ hitter.SB_CN }}</td>
              <td class="">{{ hitter.SH_CN }}</td>
              <td class="">{{ hitter.BB_CN }}</td>
              <td class="">{{ hitter.KK_CN }}</td>
              <td class="">{{ hitter.GD_TP_CN }}</td>
              <td class="">{{ hitter.ERR_CN }}</td>
            </tr>
          </template>
          <tr>
            <th colspan="3">투수</th>

            <th>이닝</th>
            <th>타자</th>
            <th>투구</th>
            <th>타수</th>
            <th>안타</th>
            
            <th>홈런</th>
            <th>희타</th>
            <th>볼넷</th>
            <th>삼진</th>
            <th>실점</th>
            <th>자책</th>
          </tr>
          <template v-if="pitcherData.length === 0">
            <tr>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
          </template>
          <template v-else-if="pitcherData.length &gt; 0">
            <tr v-for="pitcher in pitcherData" :key="pitcher.P_ID">
              <td class="sub-tit" :class="`${pitcher.T_ID == 'NC' ? 'home' : 'away'}`">{{ pitcher.POS_SC }}</td>
              <!-- <td class="sub-tit">{{ pitcher.ORDERS }}</td> -->
              <td class="sub-tit" :class="`${pitcher.T_ID == 'NC' ? 'home' : 'away'}`">{{ pitcher.RESULT_SC }}</td>
              <td class="sub-tit" :class="`${pitcher.T_ID == 'NC' ? 'home' : 'away'}`">{{ pitcher.P_NM }}</td>

              <td class="">{{ pitcher.INN_NO }}</td>
              <td class="">{{ pitcher.PA_CN }}</td>
              <td class="">{{ pitcher.BALLCNT }}</td>
              <td class="">{{ pitcher.AB_CN }}</td>
              <td class="">{{ pitcher.HIT_CN }}</td>

              <td class="">{{ pitcher.HR_CN }}</td>
              <td class="">{{ pitcher.SH_CN }}</td>
              <td class="">{{ pitcher.BB_CN }}</td>
              <td class="">{{ pitcher.KK_CN }}</td>
              <td class="">{{ pitcher.RUN_CN }}</td>
              <td class="">{{ pitcher.ER_CN }}</td>
            </tr>
          </template>
        </table>
      </div>
    </div>
  </div>	
  
</template>
<script>

export default{ 
  name:'DetailRecordTable',
  components:{},
  props: {
    teamName: {
      type: String
    },
    pitcherData: {
      type: Object
    },
    hitterData: {
      type: Object
    }
  },
  data() {
    return{
      sampleData: ''
    };
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {}
}
</script>
