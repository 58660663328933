<template>
  <div class="box">
    <p class="tb-tit">
      타자
      <span v-if="hitterData.seasonData?.BAT_P_ID_NM">{{ hitterData.seasonData.BAT_P_ID_NO }} {{ hitterData.seasonData.BAT_P_ID_NM }} {{ hitterData.seasonData.BAT_DIREC_NM }}</span>
    </p>
    <div class="tb-box">
      <table class="tb-mini">
        <colgroup>
          <col width="59px">
        </colgroup>
        <tr>
          <th>{{ gameInfo.gameId.substr(0, 4) }}</th>
          <th>타율</th>
          <th>타점</th>
          <th>안타</th>
          <th>홈런</th>
          <th>득점</th>
          <th>도루</th>
          <th>희타</th>
          <th>볼넷</th>
          <th>삼진</th>
          <th>병살</th>
        </tr>
        <tr>
          <td class="sub-tit">시즌</td>
          <td>{{ hitterData.seasonData?.HRA_RT }}</td>
          <td>{{ hitterData.seasonData?.RBI_CN }}</td>
          <td>{{ hitterData.seasonData?.HIT_CN }}</td>
          <td>{{ hitterData.seasonData?.HR_CN }}</td>
          <td>{{ hitterData.seasonData?.RUN_CN }}</td>
          <td>{{ hitterData.seasonData?.SB_CN }}</td>
          <td>{{ hitterData.seasonData?.SH_CN }}</td>
          <td>{{ hitterData.seasonData?.BB_CN }}</td>
          <td>{{ hitterData.seasonData?.KK_CN }}</td>
          <td>{{ hitterData.seasonData?.GD_CN }}</td>
        </tr>
        <tr>
          <td class="sub-tit">최근5경기</td>
          <td>{{ hitterData.lastGameData?.HRA_RT }}</td>
          <td>{{ hitterData.lastGameData?.RBI_CN }}</td>
          <td>{{ hitterData.lastGameData?.HIT_CN }}</td>
          <td>{{ hitterData.lastGameData?.HR_CN }}</td>
          <td>{{ hitterData.lastGameData?.RUN_CN }}</td>
          <td>{{ hitterData.lastGameData?.SB_CN }}</td>
          <td>{{ hitterData.lastGameData?.SH_CN }}</td>
          <td>{{ hitterData.lastGameData?.BB_CN }}</td>
          <td>{{ hitterData.lastGameData?.KK_CN }}</td>
          <td>{{ hitterData.lastGameData?.GD_CN }}</td>
        </tr>
        <tr>
          <td class="sub-tit">VS 상대팀</td>
          <td>{{ hitterData.versusData?.HRA_RT }}</td>
          <td>{{ hitterData.versusData?.RBI_CN }}</td>
          <td>{{ hitterData.versusData?.HIT_CN }}</td>
          <td>{{ hitterData.versusData?.HR_CN }}</td>
          <td>{{ hitterData.versusData?.RUN_CN }}</td>
          <td>{{ hitterData.versusData?.SB_CN }}</td>
          <td>{{ hitterData.versusData?.SH_CN }}</td>
          <td>{{ hitterData.versusData?.BB_CN }}</td>
          <td>{{ hitterData.versusData?.KK_CN }}</td>
          <td>{{ hitterData.versusData?.GD_CN }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>

import BroadcastService from '@/utils/service/broadcastService';

export default{ 
  name:'SituationHitterView',
  components:{},
  props: {
    gameInfo: {
      type: Object
    }
  },
  data() {
    return{
      broadcastService: new BroadcastService(),
      hitterData: new Object()
    };
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  watch: {
    "gameInfo.gameId"() {
        this.getGameHitter();
    },
  },
  methods: {
    getGameHitter() {
      if(this.gameInfo.gameId) {  //경기 있는 날
        this.broadcastService.$getGameHitter(this.gameInfo.srId, this.gameInfo.gameId).then(result => {
          if(result.data.code == "100") {
            this.hitterData.seasonData = result.data.seasonData;
            this.hitterData.lastGameData = result.data.lastGameData;
            this.hitterData.versusData = result.data.versusData;
          }
        });
      } else {  // 경기 없는 날
        this.hitterData = { seasonData: {}, lastGameData: {}, versusData: {}};
      }
    }
  }
}
</script>
