<template>
  <div id="wrap">
    <HeaderView ref="header" @init="init" @open-popup="openPopup"></HeaderView>

    <!-- container -->
    <div id="container">
      <div id="contents" :class="{'gamenote': $route.name == 'gamenote',
                                  'broadcast': $route.name == 'broadcast',
                                  'report': $route.name == 'report'}">
        <template v-if="this.$route.name.indexOf('broadcast') &gt; -1">
          <BroadcastView ref="broadcast"></BroadcastView>
        </template>
        <template v-else-if="this.$route.name.indexOf('report') &gt; -1">
          <ReportView ref="report" @open-popup="openPopup" @session="getSession"></ReportView>
        </template>
        <template v-else-if="this.$route.name.indexOf('gamenote') &gt; -1">
          <GameNoteView ref="gamenote"></GameNoteView>
        </template>
        <template v-else-if="this.$route.name.indexOf('interview') &gt; -1">
          <InterviewView ref="interview" @session="getSession"></InterviewView>
        </template>
        <template v-else-if="this.$route.name.indexOf('notice') &gt; -1">
          <NoticeView ref="notice" @session="getSession"></NoticeView>
        </template>
        <template v-else>
          <CalculationView ref="calculation" @open-popup="openPopup" @session="getSession"></CalculationView>
        </template>
      </div>
    </div>
    <!-- // container -->
  </div>
  <PopupView v-if="isPopupShow" @close-popup="isPopupShow = false" @go-event="goEvent" :popup-info="this.popup"></PopupView>
</template>
<script>

import HeaderView from '@/components/Layout/HeaderView.vue';
import BroadcastView from '@/views/BroadcastView.vue';
import ReportView from '@/views/ReportView.vue';
import GameNoteView from '@/views/GameNoteView.vue';
import InterviewView from '@/views/InterviewView.vue';
import NoticeView from '@/views/NoticeView.vue';
import CalculationView from '@/views/CalculationView.vue';
import PopupView from '@/components/Layout/PopupView.vue'


export default{ 
  name:'MainView',
  components:{HeaderView, BroadcastView, ReportView, GameNoteView, InterviewView, NoticeView, CalculationView, PopupView},
  data() {
    return{
      // 팝업 관련 data
      isPopupShow: false,
      popup:{
        content: '',      // 팝업창 문구
        buttonType: ''    // 1 : 버튼 1개(확인), 2: 버튼 2개 (취소, 확인)
      }
    };
  },
  setup() {},
  created() {},
  mounted() {
      this.getSession();
  },
  unmounted() { },
  methods: {
    // 팝업 오픈
    openPopup(data){
      this.isPopupShow = true;
      this.popup = data;
    },

    init(data){
      if(data){
        switch (this.$route.name){
          case 'report':
            this.$refs.report.initData();
            break;
          case 'notice':
            this.$refs.notice.initData();
            break;
          case 'interview':
            this.$refs.interview.initData();
            break;
        }
      }
    },

    getSession(){
      this.$refs.header.getSession();
    },

    goEvent(data){
      console.log(data)
      this.$router.push(`/${data.section}/${data.seqNo}`);
      //this.objCrud = {isConfirm: true, seqNo: data.seqNo, type: data.funcType}
    },
  }
}
</script>