import axios from 'axios';

class BroadcastService {

  // 상단 스코어보드
  async $getScoreBoard(gameId) {
    const result = axios.get('/Broadcast/scoreboard', {
      params: {
        gameId: gameId
      }
    });
    return await result;
  }

  // KEY POINT
  async $getKeyPoint(categoryCd, startSeqNo, endSeqNo, innNo, seasonId, gameId) {
    const result = axios.get('/Broadcast/keypoint', {
      params: {
        categoryCd: categoryCd
        , startSeqNo: startSeqNo
        , endSeqNo: endSeqNo
        , innNo: innNo
        , seasonId: seasonId
        , gameId: gameId
      }
    });
    return await result;
  }

  // 전체 중계
  async $getLivetextFull(startSeqNo, endSeqNo, innNo, gameId) {
    const result = axios.get('/Broadcast/livetextfull', {
      params: {
        startSeqNo: startSeqNo
        , endSeqNo: endSeqNo
        , innNo: innNo
        , gameId: gameId
      }
    });
    return await result;
  }

  // 요약 중계
  async $getLivetextSimple(startSeqNo, endSeqNo, innNo, gameId) {
    const result = axios.get('/Broadcast/livetextsimple', {
      params: {
        startSeqNo: startSeqNo
        , endSeqNo: endSeqNo
        , innNo: innNo
        , gameId: gameId
      }
    });
    return await result;
  }

  // 경기 상황
  async $getGameSituation(gameId) {
    const result = axios.get('/Broadcast/gameSituation', {
      params: {
        gameId: gameId
      }
    });

    return await result;
  }

  // 그라운드 상황
  async $getGroundSituation(srId, gameId) {
    const result = axios.get('/Broadcast/groundSituation', {
      params: {
        srId: srId
        , gameId: gameId
      }
    });

    return await result;
  }

  // 타자
  async $getGameHitter(srId, gameId) {
    const result = axios.get('/Broadcast/gameHitter', {
      params: {
        srId: srId
        , gameId: gameId
      }
    });

    return await result;
  }

  // 투수
  async $getGamePitcher(srId, gameId) {
    const result = axios.get('/Broadcast/gamePitcher', {
      params: {
        srId: srId
        , gameId: gameId
      }
    });

    return await result;
  }

  // 홈런 리스트
  async $getHomerunList(gameId, dataSection) {
    const result = axios.get('/Broadcast/homerunList', {
      params: {
        gameId: gameId,
        dataSection: dataSection
      }
    });

    return await result;
  }

  // 홈런 상세기록
  async $getHomerunDetail(homerunInfo, dataSection) {
    const result = axios.get('/Broadcast/homerunDetail', {
      params: {
        gameId: homerunInfo.G_ID
        , seqNo: homerunInfo.SEQ_NO
        , batPId: homerunInfo.BAT_P_ID
        , afterSeasonHrCn: homerunInfo.AFTER_SEASON_HR_CN
        , beforeSeasonHrCn: homerunInfo.BEFORE_SEASON_HR_CN
        , dataSection: dataSection
      }
    });

    return await result;
  }

  // 문자중계 - 기록
  async $getRecord(gameId){
    const result = axios.get('/Broadcast/record', {
      params: {
        gameId: gameId
      }
    })
    return await result;
  }

  // 문자중계 - 투구 분석표 - 투수 리스트
  async $getPitcherList(gameId, teamId, dataSection){
    const result = axios.get('/Broadcast/pitcherList', {
      params: {
        gameId: gameId,
        teamId: teamId,
        dataSection: dataSection
      }
    })
    return await result;
  }

  // 문자중계 - 투구 분석표 데이터
  async $getAnalysis(gameId, homePitcher, awayPitcher, dataSection){
    const result = axios.get('/Broadcast/analysis', {
      params: {
        gameId: gameId,
        homePitcher: homePitcher,
        awayPitcher: awayPitcher,
        dataSection: dataSection
      }
    })
    return await result;
  }

  // 문자중계 - 경기 이슈
  async $getGameIssue(gameId){
    const result = axios.get('/Broadcast/gameIssue', {
      params: {
        gameId: gameId
      }
    })
    return await result;
  }
}

export default BroadcastService;