<template>
  <div class="board game">
    <div class="title">경기 상황</div>
    <div class="ground">
      <p v-if="gameInfo.gameId" class="status">&bull; {{ gameState }}</p>
      <div v-if="groundStatus.length" class="position">
        <span v-for="player in groundStatus" :key="player.P_ID" :class="`player_${player.POS_SC}`">{{ player.P_NM }}</span>
        <span class="player_1">{{ playerStatus.PIT_P_NM }}</span>
        <!-- 양타인 선수 우타자로 표출(지수민님 요청) -->
        <span :class="`player_${playerStatus.HIT_DIREC_CD == '10' ? 'r' : 'l'} now`">{{ playerStatus.BAT_P_NM }}</span> 
        <!-- 주자 상황 -->
        <span v-if="runStatus.B1_BAT_ORDER_NO_NAME" :class="`player_1 now`">{{ runStatus.B1_BAT_ORDER_NO_NAME }}</span>
        <span v-if="runStatus.B2_BAT_ORDER_NO_NAME" :class="`player_2 now`">{{ runStatus.B2_BAT_ORDER_NO_NAME }}</span> 
        <span v-if="runStatus.B3_BAT_ORDER_NO_NAME" :class="`player_3 now`">{{ runStatus.B3_BAT_ORDER_NO_NAME }}</span> 
      </div>
      <ul class="bso">
        <li class="b">
          <p>B</p>
          <span v-for="i in 4" :key="i" :class="{ 'on' : playerStatus?.BALL_CN >= i}"></span>
        </li>
        <li class="s">
          <p>S</p>
          <span v-for="i in 3" :key="i" :class="{ 'on' : playerStatus?.STRIKE_CN >= i}"></span>
        </li>
        <li class="o">
          <p>O</p>
          <span v-for="i in 3" :key="i" :class="{ 'on' : playerStatus?.OUT_CN >= i}"></span>
        </li>
      </ul>
      <div class="match">
        <p class="tit">시즌 맞대결</p>
        <ul v-if="groundStatus.length">
          <li>{{ playerStatus.PIT_P_NM }} VS {{ playerStatus.BAT_P_NM }} :&nbsp;&nbsp;</li>
          <li>{{ playerStatus.AB_CN }}타수&nbsp;&nbsp;</li>
          <li>{{ playerStatus.HIT_CN }}안타&nbsp;&nbsp;</li>
          <li>{{ playerStatus.HR_CN }}홈런&nbsp;&nbsp;</li>
          <li>{{ playerStatus.KK_CN }}삼진&nbsp;&nbsp;</li>
          <li>타율{{ playerStatus.HRA_RT }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import BroadcastService from '@/utils/service/broadcastService';

export default{ 
  name:'SituationGroundView',
  components:{},
  props: {
    gameInfo: {
      type: Object
    }
  },
  data() {
    return{
      broadcastService: new BroadcastService(),
      gameStatus: new Object(),
      playerStatus: new Object(),
      groundStatus: new Array(),
      runStatus: new Object()
    };
  },
  
  setup() {},
  created() {},
  mounted() {
  },
  unmounted() {},
  computed: {
    // gameSituation() {
    //   return this.getGameSituation();
    // },

    // groundSituation() {
    //   return this.getGroundSituation();
    // },
    gameState() {
        const statusMap = {
          '1': '경기 예정',
          '2': `${this.gameStatus.INN_NO}회 ${this.gameStatus.TB_SC == 'T' ? '초' : '말'}`,
          '3': '경기 종료',
          '4': '경기 취소',
        }

        return statusMap[this.gameStatus.SECTION_ID] || '경기 예정';
    }
  },
  watch: {
    "gameInfo.gameId"() {
        this.getGameSituation();
        this.getGroundSituation();
    }
  },
  methods: {
    getGameSituation() {
      if(this.gameInfo.gameId) {  //경기 있는 날
        this.broadcastService.$getGameSituation(this.gameInfo.gameId).then(result => {
          if(result.data.code == "100") {
            this.gameStatus = result.data.gameStatus;
            this.playerStatus = result.data.playerStatus;

            if(this.gameStatus.SECTION_ID != '2') {
              this.$emit('stopInterval', true);
            }
          }
        });
      } else {  // 경기 없는 날
        this.gameStatus = {};
        this.playerStatus = {};
      }
    },

    getGroundSituation() {
      if(this.gameInfo.gameId) {  // 경기 있는 날
        this.broadcastService.$getGroundSituation(this.gameInfo.srId, this.gameInfo.gameId).then(result => {
          if(result.data.code == "100") {
            this.groundStatus = result.data.groundStatus;
            this.runStatus = result.data.runStatus;
          }
        });
      } else {  // 경기 없는 날 
        this.groundStatus = [];
        this.runStatus = {};
      }
    }
  }
}
</script>
