<template>
  <div ref="Content">
    <div class="box">
      <span class="date">{{ gameDate }}</span>
      <p class="tit-team">{{ gameInfo.awayNm }}<span>vs</span>{{ gameInfo.homeNm }}</p>
      <ul class="toggle">
        <li :class="{on:dataSection == '1'}"><a href="#" @click.prevent="changeDataSection('1')">2I</a></li>
        <li :class="{on:dataSection == '2'}"><a href="#" @click.prevent="changeDataSection('2')">NC</a></li>
      </ul>
      <ul class="list">
        <li>
          <span>{{ gameInfo.awayNm }}</span>
          <PitcherList :game-info="gameInfo" :data-section="dataSection" team-sc="T" @playerChange="awayPlayer"></PitcherList>
        </li>
        <li>
          <span>{{ gameInfo.homeNm }}</span>
          <PitcherList :game-info="gameInfo" :data-section="dataSection" team-sc="B" @playerChange="homePlayer"></PitcherList>
        </li>
      </ul>
      <a class="btn-print" href="#" @click="printContent">
        <img src="@/assets/images/contents/icon_print.png" alt="print" />
      </a>
      <a class="btn-pdf" href="#" @click="downloadPDF">
        <img src="@/assets/images/contents/icon_pdf.png" alt="pdf" />
      </a>
    </div>
    <DetailAnalyzeTable :analysis-data="homeAnalysis" :inning-data="homeInning" :max-inning="maxInning" :team-info="homeTeamInfo"></DetailAnalyzeTable>
    <DetailAnalyzeTable :analysis-data="awayAnalysis" :inning-data="awayInning" :max-inning="maxInning" :team-info="awayTeamInfo"></DetailAnalyzeTable>
  </div>
</template>
<!-- <style>
@media print {
    @page {
        size: landscape; 
    }
}
</style> -->
<script>

import PitcherList from '@/components/Common/PitcherListView.vue';
import DetailAnalyzeTable from '@/components/Broadcast/Detail/DetailAnalyzeTable.vue';
import BroadcastService from "@/utils/service/broadcastService.js"
import Common from "@/utils/common.js"
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default{ 
  name:'DetailAnalyze',
  components:{PitcherList, DetailAnalyzeTable},
  props: {
    gameInfo: {
      type: Object
    }
  },
  data() {
    return{
      broadcastService: new BroadcastService(),
      common: new Common(),
      gameId: '',
      awayPitcherId: '0', // 원정 투수
      homePitcherId: '0', // 홈투수
      awayPitcherName: '', // 원정 투수 이름
      homePitcherName: '', // 홈 투수 이름
      awayAnalysis: [],   // 원정 투구 분석표 - 구종
      homeAnalysis: [],   // 홈 투구 분석표 - 구종
      awayInning: [],     // 원정 투구 분석표 - 이닝
      homeInning: [],     // 홈 투구 분석표 - 이닝
      awayTeamInfo: '',   // 원정 (팀, 선수)
      homeTeamInfo: '',   // 홈팀 (팀, 선수)
      maxInning: 12,      // 구종별 이닝 표출을 위함
      gameDate: '',       // 경기 날짜

      awayPlayerName: '',
      homePlayerName: '',
      awayTeamName: '',
      homeTeamName: '',

      dataSection: '1',
    };
  },
  watch: {
    'gameInfo.gameId': {
      handler(gameId) {
        this.gameId = gameId;
        this.awayPitcherId = '0';
        this.homePitcherId = '0';
        //console.log('watch-', gameId.substr(8, 2), gameId.substr(10, 2))
        this.getAnalysisData();
      },
    }
  },
  setup() {},
  created() {},
  mounted() {
    this.gameId = this.gameInfo.gameId;
    this.getAnalysisData();
  },
  unmounted() {},
  methods: {
    awayPlayer(player) { // 원정 투수
      // 선택된 원정 투수 이름 가져오기
      const selectElements = this.$el.querySelectorAll('.broadcast .right-zone .board.detail .box .list li select');
      const playerOption = Array.from(selectElements[0].options).find(option => option.value === player);

      if (playerOption) {
        this.awayPitcherName = playerOption.textContent || playerOption.innerText;
        this.awayPitcherName = (this.awayPitcherName != '투수') ? this.awayPitcherName : '';
      }

      this.awayPitcherId = player;
      this.getAnalysisData();
    },
    homePlayer(player) { // 홈 투수
      // 선택된 홈 투수 이름 가져오기
      const selectElements = this.$el.querySelectorAll('.broadcast .right-zone .board.detail .box .list li select');
      const playerOption = Array.from(selectElements[1].options).find(option => option.value === player);

      if (playerOption) {
        this.homePitcherName = playerOption.textContent || playerOption.innerText;
        this.homePitcherName = (this.homePitcherName != '투수') ? this.homePitcherName : '';
      }

      this.homePitcherId = player;
      this.getAnalysisData();
    },
    getAnalysisData() { // 투구분석표 데이터 호출
      this.gameDate = this.common.yyyyMMddDot(this.gameId.substring(0, 8));  // 경기이슈 경기 날짜

      if(this.gameInfo.gameId) {
        this.broadcastService.$getAnalysis(this.gameId, this.homePitcherId, this.awayPitcherId, this.dataSection).then(result => {
          if(result.status === 200) {
            //console.log('실행완료')
            this.awayAnalysis = result.data.awayStuff;  // 원정 구종별
            this.homeAnalysis = result.data.homeStuff;  // 홈 구종별
            
            this.maxInning = result.data.maxInning;  // maxInning

            this.awayInning = result.data.awayInning; // 원정 이닝별
            this.homeInning = result.data.homeInning; // 홈 이닝별
            
            this.awayTeamInfo = result.data.awayTeamInfo; // 원정 (팀, 선수)
            this.homeTeamInfo = result.data.homeTeamInfo; // 홈 (팀, 선수)

            this.awayTeamName = result.data.awayTeamName; // 원정 (팀)
            this.homeTeamName = result.data.homeTeamName; // 홈 (팀)
            this.awayPlayerName = result.data.awayPlayerName; // 원정 (선수)
            this.homePlayerName = result.data.homePlayerName; // 홈 (선수)

            // this.awayTeamInfo = result.data.awayTeamData; // 원정 (팀, 선수)
            // this.homeTeamInfo = result.data.homeTeamData; // 홈 (팀, 선수)
          } 
          else
          {
            //console.log('이건실패')
            this.awayAnalysis = [];
            this.homeAnalysis = [];
            
            this.maxInning = 12;

            this.awayInning = [];
            this.homeInning = [];
            
            this.awayTeamInfo = '';
            this.homeTeamInfo = '';

            this.awayTeamName = '';   // 원정 (팀)
            this.homeTeamName = '';   // 홈 (팀)
            this.awayPlayerName = ''; // 원정 (선수)
            this.homePlayerName = ''; // 홈 (선수)
          }
        });
      } else {
        this.awayAnalysis = [];
        this.homeAnalysis = [];
        
        this.maxInning = 12;

        this.awayInning = [];
        this.homeInning = [];
        
        this.awayTeamInfo = '';
        this.homeTeamInfo = '';

        this.awayTeamName = '';   // 원정 (팀)
        this.homeTeamName = '';   // 홈 (팀)
        this.awayPlayerName = ''; // 원정 (선수)
        this.homePlayerName = ''; // 홈 (선수)
      }
    },
    downloadPDF() { // PDF 다운로드
      let param = {
        gameId: this.gameId,
        awayPitcherId: this.awayPitcherId,
        homePitcherId: this.homePitcherId,
        dataSection: this.dataSection
      }
      const queryString = new URLSearchParams(param).toString();
      const pdfWindow = window.open(`/print/analysis?${queryString}`, '', 'width=1400,height=800');

      setTimeout(() => {
        //const body = pdfWindow.document.body;
        const body = pdfWindow.document.getElementById('container');
        setTimeout(() => {
          html2canvas(body).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            // //const pdfWidth = body.offsetWidth;
            // //const pdfHeight = body.offsetHeight;
            const pdfWidth = 330;
            const pdfHeight = canvas.height * pdfWidth / canvas.width;

            // PDF 페이지 크기 설정
            //const pdf = new jsPDF('p', 'mm', [pdfWidth, pdfHeight]);
            const pdf = new jsPDF('l', 'mm', 'a4');
            pdf.addImage(imgData, 'png', 13, 10, pdfWidth, pdfHeight);
            //pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight); 
            pdf.save('투구분석표.pdf');
            
            pdfWindow.close();
          });
        }, 2000);
      }, 1000);
    }, 
    printContent() { // 인쇄
      let param = {
        gameId: this.gameId,
        awayPitcherId: this.awayPitcherId,
        homePitcherId: this.homePitcherId,
        dataSection: this.dataSection
      }
      const queryString = new URLSearchParams(param).toString();
      const printWindow = window.open(`/print/analysis?${queryString}`, '', 'width=1400,height=800');

      setTimeout(() => {
        printWindow.document.close();
        printWindow.print();
        printWindow.onafterprint = function () {
          printWindow.close();
        };
      }, 2000);
    },

    changeDataSection(section){
      this.dataSection = section;
      this.getAnalysisData();
    }
  }
}
</script>
