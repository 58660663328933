<template>
  <h4>기록계산</h4>
  <table class="tb calc">
    <colgroup>
      <col width="135px">
      <col width="265px">
      <col width="">
      <col width="270px">
    </colgroup>
    <tr>
      <th>구분</th>
      <th>공식 및 기준</th>
      <th>계산</th>
      <th>결과</th>
    </tr>
    <!--승차-->
    <tr>
      <th class="sub-tit">승차</th>
      <td>
        <ul class="fraction">
          <li class="txt">
            (A승수 - B승수) - (A패수 - B패수)
          </li>
          <li class="txt">
            2
          </li>
        </ul>
      </td>
      <td>
        <ul class="fraction">
          <li>
            ( <input type="number" v-model="winA"> - <input type="number" v-model="winB"> ) - ( <input type="number" v-model="loseA"> - <input type="number" v-model="loseB"> )
          </li>
          <li>2
          </li>
        </ul>
        <a href="#" class="btn gray" @click.prevent="calRecord('winGap')">계산</a>
      </td>
      <td>
        <input type="text" :value="winGap" disabled>
      </td>
    </tr>
    <!--타율-->
    <tr>
      <th class="sub-tit">타율</th>
      <td>
        <ul class="fraction">
          <li class="txt">
            안타수
          </li>
          <li class="txt">
            타수
          </li>
        </ul>
      </td>
      <td>
        <ul class="fraction">
          <li>
            <input type="number" v-model="hit">
          </li>
          <li>
            <input type="number" v-model="ab">
          </li>
        </ul>
        <a href="#" class="btn gray" @click.prevent="calRecord('batAvg')">계산</a>
      </td>
      <td>
        <input type="text" :value="batAvg" disabled>
      </td>
    </tr>
    <!--장타율-->
    <tr>
      <th class="sub-tit">장타율</th>
      <td>
        <ul class="fraction">
          <li class="txt">
            루타
          </li>
          <li class="txt">
            타수
          </li>
        </ul>
      </td>
      <td>
        <ul class="fraction">
          <li>
            <input type="number" v-model="baseSLG">
          </li>
          <li>
            <input type="number" v-model="abSLG">
          </li>
        </ul>
        <a href="#" class="btn gray" @click.prevent="calRecord('batSLG')">계산</a>
      </td>
      <td>
        <input type="text" :value="batSLG" disabled>
      </td>
    </tr>
    <!--출루율-->
    <tr>
      <th class="sub-tit">출루율</th>
      <td>
        <ul class="fraction">
          <li class="txt">
            안타수 + 볼넷 + 사구
          </li>
          <li class="txt">
            타수 + 볼넷 + 사구 + 희비
          </li>
        </ul>
      </td>
      <td>
        <ul class="fraction">
          <li>
            <input type="number" v-model="hitCn"> + <input type="number" v-model="bbCn"> + <input type="number" v-model="hpCn">
          </li>
          <li>
            <input type="number" v-model="abCn"> + <input type="number" v-model="bbCn"> + <input type="number" v-model="hpCn"> + <input type="number" v-model="sfCn">
          </li>
        </ul>
        <a href="#" class="btn gray" @click.prevent="calRecord('obp')">계산</a>
      </td>
      <td>
        <input type="text" :value="obp" disabled>
      </td>
    </tr>
    <!--평균자책점-->
    <tr>
      <th class="sub-tit">평균자책점</th>
      <td>
        <ul class="fraction">
          <li class="txt">
            투수의 자책점 × 9
          </li>
          <li class="txt">
            투구횟수
          </li>
        </ul>
      </td>
      <td>
        <ul class="fraction">
          <li>
            <input type="number" v-model="er"> × 9
          </li>
          <li>
            <input type="number" v-model="inn">
            <p class="noti">* 투구횟수는 이닝 입력<br/>(ex. 200.1)</p>
          </li>
        </ul>
        <a href="#" class="btn gray" @click.prevent="calRecord('era')">계산</a>
      </td>
      <td>
        <input type="text" :value="era" disabled>
      </td>
    </tr>

    <!--WHIP-->
    <tr>
      <th class="sub-tit">WHIP</th>
      <td>
        <ul class="fraction">
          <li class="txt">
            볼넷 + 안타
          </li>
          <li class="txt">
            이닝
          </li>
        </ul>
      </td>
      <td>
        <ul class="fraction">
          <li>
            <input type="number" v-model="whipBB"> + <input type="number" v-model="whipHit">
          </li>
          <li>
            <input type="number" v-model="whipInn">
          </li>
        </ul>
        <a href="#" class="btn gray" @click="calRecord('whip')">계산</a>
      </td>
      <td>
        <input type="text" :value="whip" disabled>
      </td>
    </tr>

    <!--승률-->
    <tr>
      <th class="sub-tit">승률</th>
      <td>
        <ul class="fraction">
          <li class="txt">
            승
          </li>
          <li class="txt">
            (승 + 패)
          </li>
        </ul>
      </td>
      <td>
        <ul class="fraction">
          <li>
            <input type="number" v-model="win">
          </li>
          <li>
            ( <input type="number" v-model="win"> + <input type="number" v-model="lose"> )
          </li>
        </ul>
        <a href="#" class="btn gray" @click="calRecord('winRate')">계산</a>
      </td>
      <td>
        <input type="text" :value="winRate" disabled>
      </td>
    </tr>

  </table>
</template>
<script>

export default{ 
  name:'CalRecordView',
  components:{},
  emits: ['open-popup'],
  data() {
    return{
      winGap: '',      // 승차
      batAvg: '',      // 타율
      batSLG: '',      // 장타율
      obp: '',         // 출루율
      era: '',         // 평균자책점
      whip: '',        // WHIP
      winRate: '',     // 승률
    };
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {

    // 기록계산
    calRecord(section){
      switch (section){
        // 승차
        case "winGap" :
          if(this.winA >= 0 && this.winB >= 0 && this.loseA >= 0 && this.loseB >= 0){
            this.winGap = ((this.winA - this.winB) - (this.loseA - this.loseB)) / 2;
          } else {
            this.$emit('open-popup', {content: '숫자를 입력해주세요.', buttonType: 1});
          }
          break;

        // 타율
        case "batAvg" :
          if(this.hit >= 0 && this.ab >= 0){
            this.batAvg = this.hit / this.ab;
            this.batAvg = this.batAvg.toFixed(3);
          } else {
            this.$emit('open-popup', {content: '숫자를 입력해주세요.', buttonType: 1});
          }
          break;

        // 장타율
        case "batSLG" :
          if(this.baseSLG >= 0 && this.abSLG >= 0){
            this.batSLG = this.baseSLG / this.abSLG;
            this.batSLG = this.batSLG.toFixed(3);
          } else {
            this.$emit('open-popup', {content: '숫자를 입력해주세요.', buttonType: 1});
          }
          break;

        // 출루율
        case "obp" :
          if(this.hitCn >= 0 && this.bbCn >= 0 && this.hpCn >= 0 && this.abCn >= 0 && this.sfCn >= 0){
            this.obp = (this.hitCn + this.bbCn + this.hpCn) / (this.abCn + this.bbCn + this.hpCn + this.sfCn);
            this.obp = this.obp.toFixed(3);
          } else {
            this.$emit('open-popup', {content: '숫자를 입력해주세요.', buttonType: 1});
          }
          break;

        // 평균자책점  
        case "era" :
          if(this.er >= 0 && this.inn >= 0){
            // 이닝 정수인 경우
            if(Number.isInteger(this.inn)){
              this.era = (this.er * 9) / this.inn; 
            } else { // 이닝 xxx.1 or xxx.2 인 경우
              let point  = (this.inn % 1).toFixed(1) * 10;
              this.era = (this.er * 9) / ((Math.round(this.inn) * 3 + Number(point)) / 3);
            }
            this.era = this.era.toFixed(2);
          } else {
            this.$emit('open-popup', {content: '숫자를 입력해주세요.', buttonType: 1});
          }
          break;

        // WHIP
        case "whip" :
          if(this.whipBB >= 0 && this.whipHit >= 0 && this.whipInn >= 0){
            // 이닝 정수인 경우
            if(Number.isInteger(this.whipInn)){
              this.whip = (this.whipBB + this.whipHit) / this.whipInn;
            } else { // 이닝 xxx.1 or xxx.2 인 경우
              let point  = (this.whipInn % 1).toFixed(1) * 10;
              this.whip = (this.whipBB + this.whipHit) / ((Math.round(this.whipInn) * 3 + Number(point)) / 3);
            }
            this.whip = this.whip.toFixed(2);
          } else {
            this.$emit('open-popup', {content: '숫자를 입력해주세요.', buttonType: 1});
          }
          break;

        // 승률
        case "winRate" :
          if(this.win >= 0 && this.lose >= 0){
            this.winRate = this.win / (this.win + this.lose);
            this.winRate = this.winRate.toFixed(3);
          } else {
            this.$emit('open-popup', {content: '숫자를 입력해주세요.', buttonType: 1});
          }
          break;
      }
    }
  }
}
</script>
